import { useContext, useEffect, useState } from 'react';
import Table from 'react-bootstrap/Table';

import { api, end, GlobalContext, PKG, EtapaIcon } from '../../../config/imports';

function Processos() {
  const { checkLogin, logout } = useContext(GlobalContext);
  const { theme } = useContext(PKG.PackageContext);
  const { curr } = PKG.SCR.Utils;
  const { post } = api;
  const { proc } = end;
  const [arrayProcessos, setArrayProcessos] = useState([]);
  const [filtered, setFiltered] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const get_processos = async () => {
      setLoading(true);
      try {
        const response = await post(proc.read.full, {});
        const data = response.data;
        setArrayProcessos(data);
        setFiltered(data);
      } catch (error) {
        console.log(error.response.data.message);
      }
      setLoading(false);
    };
    const status = checkLogin();
    if (status) {
      get_processos();
    } else {
      logout();
    }
  }, [checkLogin, logout, proc, post]);

  if (loading)
    return (
      <>
        <PKG.Loading />
      </>
    );

  const filter_processos = () => {
    const terms = document.getElementById('filter-procs').value;

    if (terms.length > 2) {
      let filtered_array = arrayProcessos.filter(
        ({
          CIDADE_IMOVEL,
          OPERACAO,
          CORRESPONDENTE_NOME,
          CORRETOR_NOME,
          IMOBILIARIA_NOME,
          USER_TELEFONE,
        }) =>
          CIDADE_IMOVEL.toLowerCase().includes(terms.toLowerCase()) ||
          OPERACAO.toLowerCase().includes(terms.toLowerCase()) ||
          CORRESPONDENTE_NOME.toLowerCase().includes(terms.toLowerCase()) ||
          CORRETOR_NOME.toLowerCase().includes(terms.toLowerCase()) ||
          IMOBILIARIA_NOME.toLowerCase().includes(terms.toLowerCase()) ||
          USER_TELEFONE.toLowerCase().includes(terms.toLowerCase()),
      );
      setFiltered(filtered_array);
    }

    if (terms.length < 3) {
      setFiltered(arrayProcessos);
    }
  };

  if (arrayProcessos.length || filtered.length) {
    return (
      <div className='setWidth mt-5'>
        <h4 className='hc-section-title'>Processos</h4>
        <p className='mt-2 mb-5'>
          <span className='me-2 ffSoft'>Filtrar por</span>
          <input
            type='text'
            id='filter-processos'
            className='filterTable'
            onChange={filter_processos}
            pattern='.{3,20}'
          />
        </p>
        <Table
          className={`table table-striped table-hover mb-3`}
          variant={theme}
        >
          <thead className='fs10 fw600 ffSoft text-center'>
            <tr className='divHide600'>
              <th
                scope='col'
                className=''
              >
                Correspondente
              </th>
              <th
                scope='col'
                className=' divHide800'
              >
                Corretor
              </th>
              <th
                scope='col'
                className=' divHide1000'
              >
                Imobiliária
              </th>
              <th
                scope='col'
                className=' divHide1000'
              >
                Cidade
              </th>
              <th
                scope='col'
                className=''
              >
                Operação
              </th>
              <th
                scope='col'
                className=''
              >
                Valor
              </th>
              <th
                scope='col'
                className=''
              >
                Cliente
              </th>
              <th
                scope='col'
                className=''
              >
                Telefone
              </th>
              <th
                scope='col'
                className=''
              >
                Etapa Atual
              </th>
              <th
                scope='col'
                className=''
              ></th>
            </tr>
          </thead>
          <tbody>
            {filtered &&
              filtered.map(
                ({
                  PROCESSO_CODIGO,
                  CORRESPONDENTE_NOME,
                  CORRETOR_NOME,
                  IMOBILIARIA_NOME,
                  CIDADE_IMOVEL,
                  OPERACAO,
                  VALOR_IMOVEL,
                  CLIENTE_NOME,
                  CLIENTE_TELEFONE,
                  ETAPA,
                  ETAPA_ATUAL,
                }) => (
                  <tr
                    key={PROCESSO_CODIGO}
                    className='tableSimulacoes fs10'
                  >
                    <td className='divHide600'>{CORRESPONDENTE_NOME || '-'}</td>
                    <td className='divHide800'>{CORRETOR_NOME || '-'}</td>
                    <td className='divHide1000'>{IMOBILIARIA_NOME || '-'}</td>
                    <td className='divHide1000'>{CIDADE_IMOVEL}</td>
                    <td>{OPERACAO}</td>
                    <td>{curr(VALOR_IMOVEL)}</td>
                    <td>{CLIENTE_NOME}</td>
                    <td>{CLIENTE_TELEFONE}</td>
                    <td>
                      <EtapaIcon
                        etapa={ETAPA}
                        className='me-1'
                      />
                      {ETAPA_ATUAL}
                    </td>
                    <td>
                      <span className='d-flex p-2'>
                        <PKG.IconsUI
                          info={PKG.ui.folder.open}
                          clsnm='svg14 mx-2 cGreen mousePointerHover '
                          tooltip='Abrir processo'
                          link={`/processo/${PROCESSO_CODIGO}`}
                        />
                      </span>
                    </td>
                  </tr>
                ),
              )}
          </tbody>
        </Table>
      </div>
    );
  } else {
    return null;
  }
}

export default Processos;
