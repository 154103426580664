import { LazyLoadImage } from 'react-lazy-load-image-component';
import '../assets/css/components/boximagetext.css';

export default function BoxImageText({
  image = null,
  text = 'texto',
  inverted = false,
}) {
  return (
    <section
      className={`setWidth mAuto boximagetext ${
        inverted ? 'flex-row-reverse' : ''
      } `}
    >
      <div className='boxImage'>
        <LazyLoadImage
          src={image}
          alt='foto'
        />
      </div>
      <div className='boxText'>{text}</div>
    </section>
  );
}
