import { useContext } from 'react';
import { ThreeDots } from 'react-loader-spinner';
import { PackageContext } from '..';

export default function LoadingDots({ conf, class_op }) {
  const { theme } = useContext(PackageContext);
  return (
    <div
      className={`d-flex flex-column justify-content-center align-items-center text-center w100 vh100 p-3 ${class_op}`}
    >
      <img
        src={conf.images[theme].logotext}
        className='my-3 w60 wpMax250'
        alt='logo'
      />
      <ThreeDots
        visible={true}
        height='80'
        width='80'
        color='#0084ff'
        radius='9'
        ariaLabel='three-dots-loading'
        wrapperStyle={{}}
        wrapperClass=''
      />
      <p className='mt-4 mb-2 ffSoft fs08'>Aguarde...</p>
    </div>
  );
}
