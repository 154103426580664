const tiposCartorio = {
  registro: 'Registro',
  certidao: 'Certidão',
  calculo: 'Cálculo',
  titulos: 'Títulos',
  pessoa_juridica: 'Pessoa Jurídica',
};

const tiposPrefeitura = {
  itbi: 'ITBI',
  protocolo: 'Protocolo',
  calculo: 'Cálculo',
  certidao: 'Certidão',
};

const orgaosProtocolo = [
  {
    ID: 0,
    NOME: 'Selecione',
    TABELA: 'orgaos',
    CODIGO: 'secondary',
    tipos: {},
  },
  {
    ID: 1,
    NOME: 'Cartório de Imóveis de Taubaté',
    TABELA: 'orgaos',
    CODIGO: 'secondary',
    tipos: { ...tiposCartorio },
  },
  {
    ID: 2,
    NOME: 'Cartório de Imóveis de Tremembé',
    TABELA: 'orgaos',
    CODIGO: 'secondary',
    tipos: { ...tiposCartorio },
  },
  {
    ID: 3,
    NOME: 'Cartório de Imóveis de SJC',
    TABELA: 'orgaos',
    CODIGO: 'secondary',
    tipos: { ...tiposCartorio },
  },
  {
    ID: 4,
    NOME: 'Outro Cartório de Imóveis',
    TABELA: 'orgaos',
    CODIGO: 'secondary',
    tipos: { ...tiposCartorio },
  },
  {
    ID: 5,
    NOME: 'Prefeitura de Taubaté',
    TABELA: 'orgaos',
    CODIGO: 'secondary',
    tipos: { ...tiposPrefeitura },
  },
  {
    ID: 6,
    NOME: 'Prefeitura de Tremembé',
    TABELA: 'orgaos',
    CODIGO: 'secondary',
    tipos: { ...tiposPrefeitura },
  },
  {
    ID: 7,
    NOME: 'Prefeitura de SJC',
    TABELA: 'orgaos',
    CODIGO: 'secondary',
    tipos: { ...tiposPrefeitura },
  },
  {
    ID: 8,
    NOME: 'Outra Prefeitura',
    TABELA: 'orgaos',
    CODIGO: 'secondary',
    tipos: { ...tiposPrefeitura },
  },
  {
    ID: 9,
    NOME: 'Receita Federal',
    TABELA: 'orgaos',
    CODIGO: 'secondary',
    tipos: {
      protocolo: 'Protocolo',
    },
  },
  {
    ID: 10,
    NOME: 'Outro',
    TABELA: 'orgaos',
    CODIGO: 'secondary',
    tipos: {
      protocolo: 'Protocolo',
    },
  },
];

export { orgaosProtocolo, tiposCartorio, tiposPrefeitura };
