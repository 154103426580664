import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { FaleConoscoForm } from '..';

export default function FaleConosco({
  typeButton = true,
  textButton = 'Fale agora com um especialista',
  pClass = '',
  pTitle = '',
  clsnmCallForm = '',
}) {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <div className=''>
      {typeButton ? (
        <button
          className='btn btn-primary'
          onClick={handleShow}
        >
          {textButton}
        </button>
      ) : (
        <p
          className={pClass}
          onClick={handleShow}
        >
          {textButton}
        </p>
      )}

      <Modal
        show={show}
        onHide={handleClose}
        data-bs-theme='dark'
      >
        <Modal.Header closeButton>
          <Modal.Title>Fale conosco agora</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <FaleConoscoForm
            clsnmCallForm={clsnmCallForm}
            title={pTitle}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant='secondary'
            onClick={handleClose}
          >
            Desistir
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}
