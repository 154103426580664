import { useContext, useEffect, useState } from 'react';
import {
  GlobalContext,
  RenderSectionButton,
} from '../../config/imports';

export default function RenderSectionLine() {
  const [filtered, setFiltered] = useState([]);
  const { setShowSection, store } = useContext(GlobalContext);
  const { dis, sec } = store();

  const sections = [
    'simulacoes',
    'tasks',
    'financiamentos',
    'protocolos',
    'processos',
    'clientes',
    'admin',
  ];

  useEffect(() => {
    const filteredSections = () => {
      if (dis === null) {
        return sections;
      }
      const disallow = dis.split(',');
      return sections.filter((section) => !disallow.includes(section));
    };

    const sect = filteredSections();
    setShowSection(sec);
    setFiltered(sect);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className='px-5 gorow justify-content-around menuSectionLine'>
      {filtered &&
        filtered.map((section) => {
          return (
            <RenderSectionButton
              key={section}
              section={section}
              classn='menuSectionCard'
              clk={() => setShowSection(section)}
            />
          );
        })}
    </div>
  );
}
