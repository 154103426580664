
import { PKG } from '../../../config/imports';

function AndamentoIcon({ and, clsnm = 'svg14 me-2 textContrastColorBlue' }) {
  switch (and) {
    case 1:
      return <PKG.IconsUI info={PKG.ui.action.add.reg} clsnm={clsnm} tooltip='Andamento'/>;
    case 2:
      return <PKG.IconsUI info={PKG.ui.action.upload.reg} clsnm={clsnm} tooltip='Documento'/>;
    case 3:
      return <PKG.IconsUI info={PKG.ui.misc.tasks} clsnm={clsnm} tooltip='Compromisso'/>;
    case 4:
      return <PKG.IconsUI info={PKG.ui.social.message.rounded} clsnm={clsnm} tooltip='Mensagem'/>;
    case 5:
      return <PKG.IconsUI info={PKG.ui.social.calendar.add} clsnm={clsnm} tooltip='Agendamento'/>;
    case 22:
      return <PKG.IconsUI info={PKG.ui.misc.ticket} clsnm={clsnm} tooltip='Protocolo'/>;
    default:
      return <PKG.IconsUI info={PKG.ui.action.add.reg} clsnm={clsnm} tooltip=''/>;
  }
}

export default AndamentoIcon;
