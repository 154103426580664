import { useContext, useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { IMaskInput } from 'react-imask';
import { Link } from 'react-router-dom';

import { CONF, GlobalContext, PKG } from '../config/imports';

function Login() {
  const tenantCode = CONF.tenant_codigo;
  const [cpf, setCpf] = useState('');
  const [password, setPassword] = useState('');
  const [failedTryLogin, setFailedTryLogin] = useState(false);
  const [message, setMessage] = useState('');
  const [inputType, setInputType] = useState('password');

  const { loading, setLoading, navigate, login } = useContext(GlobalContext);
  const { theme, setAllowFullscreen } = useContext(PKG.PackageContext);

  const handleLogin = async (event) => {
    event.preventDefault();
    setLoading(true);

    const response = await login({
      cpf,
      password,
      tenantCode,
      brand: CONF.brand,
    });
    // console.log(response)
    const { success, message } = response.data;
    if (success) {
      setMessage('');
      setFailedTryLogin(false);
      setLoading(false);
      const windowWidth = window.innerWidth;
      if (windowWidth < 600) setAllowFullscreen(true);
      navigate('/');

    } else {
      setMessage(message);
      setFailedTryLogin(true);
    }

    setLoading(false);
  };

  useEffect(() => {
    setFailedTryLogin(true);
  }, [cpf, password]);

  if (loading)
    return (
      <>
        <PKG.LoadingDots conf={CONF} />
      </>
    );

  return (
    <div className='wrapper '>
      <div className='wpMax450 mAuto'>
        <header className='form-header text-center'>
          <PKG.FormHeader
            formname={'Faça o login'}
            logo={CONF.images[theme].logotext}
            clsnm='logoHeader'
          />
        </header>
          <form
            action=''
            className='w100 p-3'
          >
            <Form.Label
              htmlFor='cpf'
              className='input-label'
            >
              CPF
            </Form.Label>
            <Form.Control
              type='text'
              as={IMaskInput}
              mask='000.000.000-00'
              id='cpf'
              autoComplete='cpf'
              name='cpf'
              aria-describedby='cpf'
              onChange={({ target: { value } }) => setCpf(value)}
              className='mb-4'
            />

            <Form.Label
              htmlFor='password'
              className='input-label'
            >
              Password
            </Form.Label>
            <div className='d-flex flex-row align-items-start '>
              <Form.Control
                type={inputType}
                id='password'
                autoComplete='current-password'
                onChange={({ target: { value } }) => setPassword(value)}
              />
              {inputType === 'password' ? (
                <PKG.IC.FaRegEye
                  className='ms-2 mt-2 svg14'
                  onClick={() => setInputType('text')}
                />
              ) : (
                <PKG.IC.FaRegEyeSlash
                  className='ms-2 mt-2 svg14'
                  onClick={() => setInputType('password')}
                />
              )}
            </div>

            <p className='fc-red fw500 mt-2'>{failedTryLogin ? message : ''}</p>

            <div className='d-flex flex-row justify-content-around mt-5 mb-2'>
              <Link to='/recover'>
                <Button
                  className='no_under'
                  variant='link'
                >
                  Esqueci a senha
                </Button>
              </Link>
              <Button
                variant='primary'
                onClick={(event) => handleLogin(event)}
              >
                Efetuar Login
              </Button>
            </div>

            <div className='text-center mt-4'>
              <Link to='/register'>
                <Button
                  className='no_under'
                  variant='link'
                >
                  Primeiro acesso? Cadastre-se
                </Button>
              </Link>
            </div>
          </form>
      </div>
    </div>
  );
}

export default Login;
