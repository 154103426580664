import { useEffect } from 'react';
import '../assets/css/components/hero.css';
import { FaleConoscoForm } from '..';

export default function Hero({
  clsnmbg = '',
  clsnm = '',
  overlay = 'bgOverlay',
  applyWidth = 'w70 mAuto',
  slogan = 'Seu slogan',
  sloganClass = '',
  sloganSubText = '',
  sloganSubTextClass = '',
  form = true,
  formConf = null,
  formCreateLead = null,
  formTitle = 'Fale conosco agora',
  formClass = 'wpMax500',
  formColorMode = 'dark',
  id = 'hero',
  zoomSlogan = false,
}) {

  useEffect(() => {
    const heroSection = document.getElementById(`hero_sec_${id}`);
    heroSection.classList = `${clsnmbg || ''} ${clsnm || ''}`;

    // eslint-disable-next-line
  }, []);

  return (
    <section
      id={`hero_sec_${id}`}
      className={`${clsnm}`}
    >
      <div className={`${overlay}`}>
        <div className={applyWidth}>
          <div className='d-flex flex-row justify-content-around gocol750'>
            <div className={`d-flex flex-column justify-content-center align-items-center p-5 w50 go100W750 fs54 fw700 cWhite ${zoomSlogan ? 'zoomInOut11' : ''} `}>
              <h1 className={sloganClass}>{slogan}</h1>
              <h2 className={sloganSubTextClass}>{sloganSubText}</h2>
            </div>

            {form && (
              <FaleConoscoForm
                clsnmCallForm={formClass}
                colorMode={formColorMode}
                title={formTitle}
                conf={formConf}
                createLead={formCreateLead}
              />
            )}
          </div>
        </div>
      </div>
    </section>
  );
}
