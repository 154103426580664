import { PKG } from '../../config/imports';

function EtapaIcon({ etapa, clsnm = '' }) {
  switch (etapa) {
    case 'simulação':
      return (
        <PKG.IconsUI
          info={PKG.ui.etapas.simulacao}
          clsnm={clsnm}
        />
      );
    case 'documentação do comprador':
      return (
        <PKG.IconsUI
          info={PKG.ui.etapas.documentacao_comprador}
          clsnm={clsnm}
        />
      );
    case 'aprovação de crédito':
      return (
        <PKG.IconsUI
          info={PKG.ui.etapas.aprovacao_credito}
          clsnm={clsnm}
        />
      );
    case 'documentação do vendedor':
      return (
        <PKG.IconsUI
          info={PKG.ui.etapas.documentacao_vendedor}
          clsnm={clsnm}
        />
      );
    case 'documentação do imóvel':
      return (
        <PKG.IconsUI
          info={PKG.ui.etapas.documentacao_imovel}
          clsnm={clsnm}
        />
      );
    case 'avaliação de engenharia':
      return (
        <PKG.IconsUI
          info={PKG.ui.etapas.avaliacao_engenharia}
          clsnm={clsnm}
        />
      );
    case 'confirmação de valores':
      return (
        <PKG.IconsUI
          info={PKG.ui.etapas.confirmacao_valores}
          clsnm={clsnm}
        />
      );
    case 'assinatura de formulários':
      return (
        <PKG.IconsUI
          info={PKG.ui.etapas.assinatura_formularios}
          clsnm={clsnm}
        />
      );
    case 'análise jurídica':
      return (
        <PKG.IconsUI
          info={PKG.ui.etapas.analise_juridica}
          clsnm={clsnm}
        />
      );
    case 'assinatura do contrato':
      return (
        <PKG.IconsUI
          info={PKG.ui.etapas.assinatura_contrato}
          clsnm={clsnm}
        />
      );
    case 'prefeitura':
      return (
        <PKG.IconsUI
          info={PKG.ui.etapas.prefeitura}
          clsnm={clsnm}
        />
      );
    case 'cartório':
      return (
        <PKG.IconsUI
          info={PKG.ui.etapas.cartorio}
          clsnm={clsnm}
        />
      );
    case 'finalizado':
      return (
        <PKG.IconsUI
          info={PKG.ui.etapas.finalizado}
          clsnm={clsnm}
        />
      );
    case 'mindmap':
      return (
        <PKG.IconsUI
          info={PKG.ui.etapas.mindmap}
          clsnm={clsnm}
        />
      );
    default:
      return '';
  }
}

export default EtapaIcon;
