import React from 'react';
import { IC, ui } from './ui';

function IconsUI({
  info,
  clsnm = '',
  click = null,
  tooltip = '',
  link = null,
  linkTargetToBlank = false,
  linkAriaLabel = null,
  linkRel = 'noreferrer',
  linkClass = '',
  sideText = '',
  sideDivClass = '',
  sideTextClass = '',
  sideTextPosition = 'right',
  canvasIgnore = false,
  id=null
}) {
  const { icon } = info;

  const newProps = {
    className: `breakAvoid ${clsnm}`,
    onClick: click,
    'data-toggle': tooltip ? 'tooltip' : null,
    'data-placement': tooltip ? 'top' : null,
    title: tooltip,
    'aria-label': tooltip,
    id: id,
  };
  const newIcon = React.cloneElement(icon, newProps);

  const elementText = () => {
    if (sideText) {
      return (
        <div
          className={`divIconsUi breakAvoid d-flex flex-row${sideTextPosition === 'right' ? '' : 'reverse'} align-items-center ${sideDivClass}`}
          onClick={click}
          data-html2canvas-ignore={canvasIgnore}

        >
          {newIcon}
          <span className={sideTextClass}>{sideText}</span>
        </div>
      );
    }
    return newIcon;
  };

  const element = elementText();

  if (link) {
    return (
      // eslint-disable-next-line react/jsx-no-target-blank
      <a
        href={link}
        target={linkTargetToBlank ? '_blank' : '_self'}
        rel={linkTargetToBlank === true ? 'noreferrer' : linkRel}
        aria-label={linkAriaLabel || tooltip || 'link'}
        className={linkClass}
        data-html2canvas-ignore={canvasIgnore}        
      >
        {element}
      </a>
    );
  }

  return element;
}

function fileIcon(type, iconClass = 'svg09') {
  if (type.includes('image'))
    return IconsUI({ info: ui.file.image, clsnm: `${iconClass} cGreen` });
  if (type.includes('pdf'))
    return IconsUI({ info: ui.file.pdf, clsnm: `${iconClass} cRed` });
  return IconsUI({ info: ui.file.other, clsnm: `${iconClass} cBlue` });
}

export { IconsUI, IC, ui, fileIcon };
