
export default function GoogleCalendarLink({
  title,
  description,
  location,
  startTime,
  endTime,
  participants,
}) {
  const encodedTitle = encodeURIComponent(title);
  const encodedDescription = encodeURIComponent(description);
  const encodedLocation = encodeURIComponent(location);
  const encodedStartTime = encodeURIComponent(startTime);
  const encodedEndTime = encodeURIComponent(endTime);
  const encodedParticipants = participants
    .map((participant) => encodeURIComponent(participant))
    .join(',');

  const googleCalendarBaseUrl =
    'https://calendar.google.com/calendar/render?action=TEMPLATE';

  const eventUrl = `${googleCalendarBaseUrl}&text=${encodedTitle}&details=${encodedDescription}&location=${encodedLocation}&dates=${encodedStartTime}/${encodedEndTime}&add=${encodedParticipants}`;

  return (
    <a
      href={eventUrl}
      target='_blank'
      rel='noreferrer'
      className='fs10 ffSoft'
    >
      Criar Agendamento no Google Calendar
    </a>
  );
}
