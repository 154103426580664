import jsPDF from 'jspdf';
import html2pdf from 'html2pdf.js';
import { CONF, api, end } from '../../config/imports';

function handleSaveAsPDF(filename) {
  const opt = {
    margin: 1,
    filename: filename,
    autoPaging: 'text',
    enableLinks: false,
    image: { type: 'jpeg', quality: 1 },
    logging: false,
    pagebreak: { mode: ['avoid-all', 'css'] },
    html2canvas: {
      autoPaging: 'text',
      allowTaint: true,
      letterRendering: true,
      logging: false,
      scale: 1,
    },
    jsPDF: {
      unit: 'cm',
      format: 'a4',
      orientation: 'portrait',
      compress: false,
      autoPaging: 'text',
      logging: false,
      putOnlyUsedFonts: true,
    },
  };
  return opt;
}

export async function download(elementId, filename) {
  const element = document.getElementById(elementId);
  const opt = handleSaveAsPDF(filename);
  html2pdf().set(opt).from(element).save();
  const blob = await html2pdf().set(opt).from(element).outputPdf('blob');
  const url = URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.href = url;
  link.target = '_blank';
  link.click();
}

export function downloadPDF(elementId, filename) {
  const jsPdf = new jsPDF('p', 'cm', 'a4');
  const element = document.getElementById(elementId);

  const opt = {
    callback: function (jsPdf) {
      jsPdf.save(filename);
    },
    margin: [1.5, 1, 1.5, 1],
    enableLinks: false,
    image: { type: 'jpeg', quality: 1 },
    compress: false,
    autoPaging: 'text',
    logging: false,
    pagebreak: { mode: ['avoid-all', 'css'] },
    putOnlyUsedFonts: true,
    html2canvas: {
      allowTaint: true,
      letterRendering: true,
      logging: false,
      pagebreak: { mode: ['avoid-all', 'css'] },
      scale: 0.1,
      unit: 'cm',
      format: 'a4',
      orientation: 'portrait',
      compress: false,
      putOnlyUsedFonts: true,
    },
  };

  jsPdf.html(element, opt);
}

export async function sendPdfToMail(
  elementId,
  filename,
  mailTo,
  subject,
  emailContent = '',
  mailToCC = '',
) {
  const element = document.getElementById(elementId);
  try {
    const logo = await api.post(end.tenants.logo_link, {
      brand: CONF.brand,
    });
    const logo_link = logo.data;

    const opt = handleSaveAsPDF(filename);
    const blob = await html2pdf().set(opt).from(element).outputPdf('blob');

    const files = [];
    const filenames = [];
    files.push(blob);
    filenames.push(opt.filename);

    const formData = new FormData();
    formData.append('email', mailTo);
    formData.append('cc', mailToCC);
    formData.append('subject', subject);

    files.forEach((file, index) => {
      formData.append('files[]', file);
      formData.append('filename[]', filenames[index]);
    });

    formData.append('content', emailContent);
    formData.append('logo_link', logo_link);
    formData.append('brand', CONF.brand);
    await api.postFilesAPI(end.bwAPI.maildocs, formData);
  } catch (error) {
    console.error(error);
  }
}
