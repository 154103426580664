function AdminCustom() {
  return (
    <>
      <h4 className='hc-section-title'>Personalizar</h4>
      <div>Linhas de Financiamento</div>
      <div>Tabelas</div>
      <div>Taxas</div>
      <div>Documentos</div>
      <div>Etapas</div>
      <div>Bancos</div>
    </>
  );
}

export default AdminCustom;
