import { useContext, useEffect, useState } from 'react';
import '../../../assets/css/app/section.news.css';

import {
  GlobalContext,
  PKG,
  NewsCharts,
  NewsTimelineItem,
  api,
  CONF,
  end,
} from '../../../config/imports';

function News() {
  const { checkLogin, setShowSection } = useContext(GlobalContext);
  const { latest, monthes, numbs } = end;
  const [logged, setLogged] = useState(false);

  const [news, setNews] = useState([]);
  const [loading, setLoading] = useState(false);
  const [numbers, setNumbers] = useState([]);
  const [months, setMonths] = useState([]);

  const cards = [
    {
      title: 'Simulações',
      icon: <PKG.IC.HiMiniAdjustmentsVertical className='svg26' />,
      color: 'info',
      value: numbers.SIMULACOES,
      section: 'simulacoes',
    },
    {
      title: 'Processos',
      icon: <PKG.IC.TbTimeline className='svg26' />,
      color: 'primary',
      value: numbers.PROCESSOS,
      section: 'processos',
    },
    {
      title: 'Crédito gerado',
      icon: <PKG.IC.TbTimeline className='svg26' />,
      color: 'success',
      value: parseFloat(numbers.TOTAL_IMOVEL_VALOR).toLocaleString('pt-BR', {
        style: 'currency',
        currency: 'BRL',
      }),
    },
    {
      title: 'Clientes',
      icon: <PKG.IC.FaUser className='svg26' />,
      color: 'warning',
      value: numbers.CLIENTES,
      section: 'clientes',
    },
    {
      title: 'Imobiliárias',
      icon: <PKG.IC.MdOutlineRealEstateAgent className='svg26' />,
      color: 'danger',
      value: numbers.IMOBILIARIAS,
      section: 'imobiliarias',
    },
    {
      title: 'Corretores',
      icon: <PKG.IC.FaPersonShelter className='svg26' />,
      color: 'secondary',
      value: numbers.CORRETORES,
      section: 'corretores',
    },
  ];

  useEffect(() => {
    const logged = checkLogin();
    setLogged(logged);

    const fetchData = async () => {
      setLoading(true);
      try {
        const [latestResponse, numbsResponse, monthesResponse] =
          await Promise.all([
            api.post(latest, { brand: CONF.brand }),
            api.post(numbs, { brand: CONF.brand }),
            api.post(monthes, { brand: CONF.brand }),
          ]);

        setNews(latestResponse.data);
        setNumbers(numbsResponse.data[0]);
        setMonths(monthesResponse.data);
      } catch (error) {
        console.error(error);
      }
      setLoading(false);
    };

    if (logged) {
      fetchData();
    }
    // eslint-disable-next-line
  }, []);

  if (loading) {
    return <PKG.Loading />;
  }

  if (!logged) return null;

  return (
    <div className='setWidth mt-5'>
      <p className='ffSoft textContrastColor'>Seus Números</p>

      <div className='cardNumbs mb-4'>
        {cards.map((card, index) => (
          <div
            onClick={() => setShowSection(card.section)}
            key={`card_${index}`}
            className={` card m-3 p-2 bg-${card.color} cWhite gorow justify-content-between align-items-center`}
          >
            <div className='m-1 text-center'>
              <div className='fs10 small'>{card.title}</div>
              <div className='fs16 fw600'>{card.value}</div>
            </div>
            {card.icon}
          </div>
        ))}
      </div>

      <p className='ffSoft textContrastColor'>Últimas atualizações</p>
      <ul className='ffReg liNews mb-5'>
        {news &&
          news.map((item, index) => (
            <NewsTimelineItem
              item={item}
              key={`news_${item.CODIGO}_${index}`}
            />
          ))}
      </ul>

      <div className='my-5 divHide divShowMin600 flex-column'>
        <p className='ffSoft textContrastColor'>
          Crédito gerado nos últimos 12 meses
        </p>
        {months.length > 0 && <NewsCharts data={months} />}
      </div>
    </div>
  );
}

export default News;
