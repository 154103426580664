import React, { useContext, useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import Spinner from 'react-bootstrap/esm/Spinner';

import {
  PackageContext,
  CustomDropdown,
  // GoogleCalendarLink,
  IconsUI,
  SCR,
  ui,
  Loading,
} from '../packages/wookie';

import {
  api,
  end,
  GlobalContext,
  DragAndDrop,
  services,
  orgaosProtocolo,
} from '../config/imports';

export default function AndamentoModal({
  show,
  setShow,
  updated,
  proc_etapa_id,
  proc_codigo,
  proc_c = true,
}) {
  const { theme } = useContext(PackageContext);
  const { a } = useContext(GlobalContext);
  const { prioridades, status, andamentos, documentos } = a;
  const [errorMessage, setErrorMessage] = useState('');

  const [loading, setLoading] = useState(false);
  const [loadingProtocolo, setLoadingProtocolo] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const filteredtipos = (orgao) => {
    return orgaosProtocolo.find((item) => item.ID === orgao).tipos;
  };

  const initialStatus = {
    processo_etapa_id: proc_etapa_id,
    andamento_id: 1,
    visivel_cliente: proc_c,
    notificar_escritorio: proc_c,
    prioridade_id: 3,
    prazo: null,
    status_id: 1,
    title: null,
    andamento: null,
    nota: null,
  };

  const initialProtocolo = {
    processo_codigo: proc_codigo,
    status_id: 1,
    orgao: 'Cartório de Imóveis de Taubaté',
    orgaokey: 1,
    numero: null,
    digito: null,
    tipokey: 'registro',
    tipo: 'Registro',
    descricao: '',
  };

  const [andamento, setAndamento] = useState(initialStatus);
  const [protocolo, setProtocolo] = useState(initialProtocolo);

  const [files, setFiles] = useState([]);
  const [andamentoNome, setAndamentoNome] = useState('andamento');

  useEffect(() => {
    window.addEventListener('resize', () => {
      setWindowWidth(window.innerWidth);
    });
  }, []);

  const handleFiles = (f) => {
    setFiles(f);
  };

  const andamentosTypes = () => {
    const clienteButtons = [
      { ID: 1, NOME: 'andamento' },
      { ID: 2, NOME: 'documento' },
    ];

    const createButton = (item) => {
      return (
        <Button
          key={`modal_andamento_${proc_etapa_id}_item_${item.ID}`}
          className='fs12 fw500 my-1 me-2 wp100'
          variant={
            andamento.andamento_id === item.ID
              ? 'outline-success'
              : `outline-secondary`
          }
          size='sm'
          onClick={() => {
            setAndamento({ ...initialStatus, andamento_id: item.ID });
            setProtocolo(initialProtocolo);
            setAndamentoNome(item.NOME);
          }}
        >
          {item.NOME}
        </Button>
      );
    };

    const buttons = () => {
      if (proc_c) {
        return clienteButtons.map((item) => createButton(item));
      }
      return andamentos.map((item) => createButton(item));
    };

    return buttons();
  };

  const handleChange = (e) => {
    setAndamento({ ...andamento, [e.target.name]: e.target.value });
  };

  const handlePrioridadeChange = (novaPrioridade) => {
    setAndamento({ ...andamento, prioridade_id: novaPrioridade });
  };

  const handleStatusChange = (novoStatus) => {
    setAndamento({ ...andamento, status_id: novoStatus });
    setProtocolo({ ...protocolo, status_id: novoStatus });
  };

  const handleSave = async () => {
    setLoading(true);
    try {
      setLoading(true);

      if (andamento.andamento_id === 2) {
        const uploadPromises = [];
        const formData = new FormData();
        for (let i = 0; i < files.length; i += 1) {
          const fileObject = files[i];
          formData.append('files', fileObject.file);
          formData.append('processo_etapa_id', proc_etapa_id);
          formData.append('documento_type', fileObject.description);
          formData.append('files_name', fileObject.file.name);
          formData.append('files_type', fileObject.file.type);
          formData.append('prioridade_id', andamento.prioridade_id);
          formData.append('visivel_cliente', andamento.visivel_cliente ? 1 : 0);
          formData.append(
            'notificar_escritorio',
            andamento.notificar_escritorio ? 1 : 0,
          );
          formData.append('andamento_id', andamento.andamento_id);

          const uploadPromise = api.postFiles(end.and.create.doc, formData);
          uploadPromises.push(uploadPromise);
        }
        await Promise.all(uploadPromises);
      } else {

        // const removeNullProperties = (obj) => {
        //   if (typeof obj !== 'object' || obj === null) return obj;
        //   Object.keys(obj).forEach(key => {
        //     if (obj[key] === null || obj[key] === undefined || obj[key] === '') { 
        //       delete obj[key];
        //     }
        //   });        
        //   return obj;
        // }

        // const payload = removeNullProperties({
        //   ...andamento,
        //   visivel_cliente: andamento.visivel_cliente ? 1 : 0,
        //   notificar_escritorio: andamento.notificar_escritorio ? 1 : 0,
        // });

        await api.post(end.and.create.andamento, {
          ...andamento,
          visivel_cliente: andamento.visivel_cliente ? 1 : 0,
          notificar_escritorio: andamento.notificar_escritorio ? 1 : 0,
        });

        if (andamento.andamento_id === 22) {
          await api.post(end.prot.create, protocolo);
        }
      }
    } catch (error) {
      console.error(error);
    }
    setLoading(false);

    setTimeout(() => {
      setLoading(false);
      setShow(false);
    }, 1000);
    updated();
  };

  return (
    <>
      <Modal
        show={show}
        fullscreen={windowWidth < 768 ? true : false}
        size='lg'
        aria-labelledby='contained-modal-title-vcenter'
        centered
        data-bs-theme={theme}
        className='cstModal'
        onHide={setShow}
      >
        <Modal.Header closeButton>
          <div className='d-flex flex-column'>
            <Modal.Title>
              Adicionar <b>{andamentoNome}</b>
            </Modal.Title>
            <p className='mt-3 mb-1'>
              Selecione o tipo de andamento que deseja adicionar:
            </p>
            <div className='d-flex flex-row flex-wrap'>{andamentosTypes()}</div>
          </div>
        </Modal.Header>
        <Modal.Body>
          <>
            <div className='d-flex flex-row flex-wrap justify-content-start align-items-center'>
              {!proc_c && (
                <>
                  <IconsUI
                    info={
                      andamento.notificar_escritorio
                        ? ui.misc.bell.on
                        : ui.misc.bell.off
                    }
                    clsnm={`svg14 mx-2 mousePointerHover ${
                      andamento.notificar_escritorio ? 'cGreen' : 'cGray500'
                    }`}
                    tooltip={
                      andamento.notificar_escritorio
                        ? 'Notificar Escritório'
                        : 'Não Notificar Escritório'
                    }
                    click={() => {
                      const previous = andamento.notificar_escritorio;
                      setAndamento({
                        ...andamento,
                        notificar_escritorio: !previous,
                      });
                    }}
                  />

                  <IconsUI
                    info={
                      andamento.visivel_cliente
                        ? ui.misc.eye.on
                        : ui.misc.eye.off
                    }
                    clsnm={`svg14 mx-2 mousePointerHover ${
                      andamento.visivel_cliente ? 'cGreen' : 'cGray500'
                    }`}
                    tooltip={
                      andamento.visivel_cliente
                        ? 'Visível ao Cliente'
                        : 'Não Visível ao Cliente'
                    }
                    click={() => {
                      const previous = andamento.visivel_cliente;
                      setAndamento({
                        ...andamento,
                        visivel_cliente: !previous,
                      });
                    }}
                  />
                </>
              )}

              <CustomDropdown
                arrayObj={prioridades}
                defaultValue={andamento.prioridade_id}
                value={andamento.prioridade_id}
                changeCallApi={handlePrioridadeChange}
                identification={proc_etapa_id}
                showLabel={'Prioridade'}
                customClass='dropStart'
              />
              {andamentoNome === 'tarefa' || andamentoNome === 'protocolo' ? (
                <>
                  <CustomDropdown
                    arrayObj={status}
                    defaultValue={andamento.status_id}
                    value={andamento.status_id}
                    changeCallApi={handleStatusChange}
                    identification={proc_etapa_id}
                    showLabel={'Status'}
                  />
                  <Form.Label
                    className='fs08 my-0 ms-1 me-2'
                    htmlFor='prazo'
                  >
                    prazo
                  </Form.Label>
                  <Form.Control
                    type='date'
                    className='fs10 wp125 px-1 faCenter'
                    size='sm'
                    id={`new_prazo_task_${proc_etapa_id}`}
                    name='prazo'
                    value={andamento.prazo}
                    onChange={(e) => {
                      handleChange(e);
                    }}
                  />
                </>
              ) : null}
            </div>

            {andamentoNome !== 'documento' ? (
              <Form.Control
                required
                type='text'
                id='title'
                name='title'
                value={andamento.title}
                placeholder='Título'
                className='form-control w100 my-1  fs12 textContrast'
                onChange={(e) => handleChange(e)}
              />
            ) : null}

            {andamentoNome === 'andamento' || andamentoNome === 'tarefa' ? (
              <>
                <Form.Group
                  className='my-3'
                  id={`new_andamento_${proc_etapa_id}`}
                >
                  <Form.Label htmlFor='andamento'>
                    <IconsUI
                      info={ui.misc.pen}
                      clsnm='svg10 me-1 cGray500'
                    />
                    <span className='ffSoft fs12 textContrast me-2'>
                      {andamentoNome === 'tarefa' ? 'Tarefa' : 'andamento'}
                    </span>
                  </Form.Label>
                  <Form.Control
                    as='textarea'
                    id='andamento'
                    name='andamento'
                    value={andamento.andamento}
                    className='form-control w100 mb-2  fs12 textContrast'
                    rows={3}
                    onChange={(e) => {
                      handleChange(e);
                    }}
                  />
                </Form.Group>

                {!proc_c && andamentoNome === 'andamento' && (
                  <Form.Group
                    className='my-3'
                    id={`new_nota_${proc_etapa_id}`}
                  >
                    <Form.Label>
                      <div className='d-flex flex-row justify-content-around align-items-center'>
                        <IconsUI
                          info={ui.misc.lock}
                          clsnm='svg10 me-1 cGray500'
                        />
                        <span className='ffSoft fs12 textContrast me-2'>
                          nota Interna
                        </span>
                      </div>
                    </Form.Label>
                    <Form.Control
                      as='textarea'
                      id='nota'
                      name='nota'
                      value={andamento.nota}
                      className='form-control w100 mb-2 fs12'
                      placeholder='nota Interna nunca será visível ao cliente ou corretor'
                      rows={2}
                      onChange={(e) => {
                        handleChange(e);
                      }}
                    />
                  </Form.Group>
                )}
              </>
            ) : null}

            {andamentoNome === 'documento' ? (
              <DragAndDrop
                onFilesSelected={handleFiles}
                filesSt={files}
                docs={documentos}
              />
            ) : null}

            {andamentoNome === 'protocolo' ? (
              <div className='d-flex flex-column flex-wrap'>
                <CustomDropdown
                  arrayObj={orgaosProtocolo}
                  changeCallApi={(novaorgao) =>
                    setProtocolo({
                      ...protocolo,
                      orgaokey: novaorgao,
                      orgao: orgaosProtocolo[novaorgao].NOME,
                      tipo: initialProtocolo.tipo,
                    })
                  }
                  identification={proc_etapa_id}
                  showLabel={'Órgão: '}
                  customClass='droporgaos'
                />
                <div className='d-flex flex-row flex-wrap'>
                  <Form.Group
                    className='my-1 me-1'
                    id={`new_protocolo_numero_${proc_etapa_id}`}
                  >
                    <Form.Label
                      className='m-0 fs10 textContrastColor ps-1'
                      htmlFor='numero'
                    >
                      Protocolo
                    </Form.Label>
                    <Form.Control
                      required
                      type='text'
                      id='numero'
                      name='numero'
                      value={protocolo.numero}
                      placeholder='Número'
                      className='form-control wp100 fs12 textContrast'
                      onChange={(e) => {
                        setProtocolo({ ...protocolo, numero: e.target.value });
                      }}
                    />
                  </Form.Group>
                  <Form.Group
                    className='my-1 me-1'
                    id={`new_protocolo_digito_${proc_etapa_id}`}
                  >
                    <Form.Label
                      className='m-0 fs10 textContrastColor ps-1'
                      htmlFor='digito'
                    >
                      Dígito
                    </Form.Label>

                    <Form.Control
                      type='text'
                      id='digito'
                      name='digito'
                      value={protocolo.digito}
                      placeholder='Dígito'
                      className='form-control wp60 fs12 textContrast'
                      onChange={(e) => {
                        setProtocolo({ ...protocolo, digito: e.target.value });
                      }}
                    />
                  </Form.Group>
                  <Form.Group
                    className='my-1 me-1'
                    id={`new_protocolo_tipo_${proc_etapa_id}`}
                  >
                    <Form.Label
                      className='m-0 fs10 textContrastColor ps-1'
                      htmlFor='tipo'
                    >
                      tipo
                    </Form.Label>
                    <div className='gorow align-items-center'>
                      <Form.Select
                        id='tipo'
                        required
                        name='tipo'
                        value={protocolo.tipokey}
                        className='form-select fs12 textContrast'
                        onChange={(e) => {
                          setProtocolo({
                            ...protocolo,
                            tipokey: e.target.value,
                            tipo: e.target.options[e.target.selectedIndex].text,
                          });
                        }}
                      >
                        <option value=''>Selecione</option>
                        {protocolo.orgao &&
                          Object.keys(filteredtipos(protocolo.orgaokey)).map(
                            (item) => (
                              <option
                                key={
                                  orgaosProtocolo[protocolo.orgaokey].tipos[
                                    item
                                  ]
                                }
                                value={item}
                                name={
                                  orgaosProtocolo[protocolo.orgaokey].tipos[
                                    item
                                  ]
                                }
                              >
                                {
                                  orgaosProtocolo[protocolo.orgaokey].tipos[
                                    item
                                  ]
                                }
                              </option>
                            ),
                          )}
                      </Form.Select>
                      {loadingProtocolo ? (
                        ''
                      ) : (
                        <IconsUI
                          info={ui.action.magnifier}
                          clsnm='svg18 cLink mx-2 mousePointerHover'
                          tooltip='Pesquisar Protocolo'
                          click={async () => {
                            setLoadingProtocolo(true);
                            setErrorMessage('');
                            if (protocolo.numero === '') {
                              setErrorMessage('Informe o número do protocolo');
                              return;
                            }

                            if (protocolo.orgaokey === 0) {
                              setErrorMessage('Selecione o órgão do protocolo');
                              return;
                            }

                            if (protocolo.orgaokey === 1) {
                              try {
                                const dataProt = await services.readCRI(
                                  [
                                    {
                                      key: 'protocolo',
                                      value: protocolo.numero,
                                    },
                                    {
                                      key: 'digitoVerificador',
                                      value: protocolo.digito,
                                    },
                                  ],
                                  protocolo.tipokey,
                                );

                                if (dataProt) {
                                  const atual = dataProt.situacaoAtual;
                                  let status;
                                  switch (atual) {
                                    case 'Em análise':
                                      status = 1;
                                      break;
                                    case 'Em exigência':
                                      status = 3;
                                      break;
                                    case 'Concluído':
                                    case 'Entregue':
                                      status = 4;
                                      break;
                                    default:
                                      status = 2;
                                      break;
                                  }

                                  handlePrioridadeChange(status);

                                  const andamentoProtocolo = `Protocolo: ${protocolo.numero}-${protocolo.digito} - ${protocolo.tipo}
Abertura:  ${dataProt.protocolo.dataAbertura}
Natureza: ${dataProt.protocolo.natureza}
Situação: ${dataProt.situacaoAtual}
Outorgado: ${dataProt.protocolo.outorgado}
Outorgante: ${dataProt.protocolo.outorgante}`;
                                  setProtocolo({
                                    ...protocolo,
                                    descricao: andamentoProtocolo,
                                    status_id: status,
                                  });
                                  setAndamento({
                                    ...andamento,
                                    prazo: SCR.Utils.convertDate(
                                      dataProt.dataPrevisao,
                                    ),
                                    status_id: status,
                                    title: `Protocolo: ${protocolo.numero}-${protocolo.digito} [${dataProt.protocolo.natureza}]`,
                                    andamento: andamentoProtocolo,
                                  });
                                }
                              } catch (error) {
                                console.error(error);
                                setErrorMessage(
                                  'Ooops... Ainda não sei consultar esse protocolo!',
                                );
                              }
                            } else {
                              setErrorMessage(
                                'Ooops... Ainda não sei consultar esse protocolo!',
                              );
                            }
                            setLoadingProtocolo(false);
                          }}
                        />
                      )}
                    </div>
                  </Form.Group>
                </div>
                {loadingProtocolo ? <Loading /> : ''}
                <p className='textContrastColorRed fs12 fw500'>
                  {errorMessage}
                </p>
                <Form.Group
                  className='my-1 me-1 w100'
                  id={`new_protocolo_descricao_${proc_etapa_id}`}
                >
                  <Form.Label
                    className='m-0 fs10 textContrastColor ps-1'
                    htmlFor='descricao'
                  >
                    Detalhes
                  </Form.Label>
                  <Form.Control
                    as='textarea'
                    rows={5}
                    id='descricao'
                    name='descricao'
                    value={protocolo.descricao}
                    placeholder='Descrição'
                    className='form-control fs12 textContrast'
                    onChange={(e) => {
                      setProtocolo({ ...protocolo, descricao: e.target.value });
                      setAndamento({ ...andamento, andamento: e.target.value });
                    }}
                  />
                </Form.Group>
              </div>
            ) : null}
          </>
        </Modal.Body>
        <Modal.Footer>
          {loading ? (
            <Spinner
              animation='border'
              variant='success'
              size='sm'
            />
          ) : (
            <>
              <Button
                size='sm'
                variant={theme}
                onClick={setShow}
              >
                Sair
              </Button>
              <Button
                size='sm'
                variant='primary'
                onClick={handleSave}
              >
                Adicionar {andamentoNome}
              </Button>
            </>
          )}
        </Modal.Footer>
      </Modal>
    </>
  );
}
