export default function ModalLine({
  title,
  first,
  second,
  double = false,
  resumo = false,
}) {
  const col = double ? 'wp300' : 'wp150';
  const wd_title = resumo ? 'fw300 wp200' : 'fs10 wp100';
  return (
    <div className='m-0 p-0 d-flex flex-row align-items-center'>
      <p className={`m-0 p-0 ${wd_title}`}>{title}</p>
      <p className={`m-0 p-0 ${col}`}>{first}</p>
      {second ? <p className='m-0 p-0 wp150'>{second}</p> : ''}
    </div>
  );
}
