import { PKG, CONF } from '../../config/imports';

import '../../assets/css/app/footer.css';

export default function Footer() {
  const y = new Date().getFullYear();

  return (
    <>
      <div className='mt-5'> </div>
      <footer>
        <div className='footer fs10'>
          <div className='d-flex flex-row justify-content-center align-items-baseline'>
            <span className='footer-sp-fale divHide700'>Fale conosco: </span>
            <PKG.LinkButton item={CONF.objLinks.instagram} />
            <PKG.LinkButton item={CONF.objLinks.whatsapp} />
            <PKG.LinkButton item={CONF.objLinks.mail} />
            <PKG.LinkButton item={CONF.objLinks.app} />
          </div>
          <span className='text-center footer-sp-reg'>
            {CONF.setup.title} <PKG.IconsUI info={PKG.ui.misc.registered} />{' '}
            Todos os direitos reservados{' '}
            <PKG.IconsUI info={PKG.ui.misc.copyright} /> {y}
          </span>
        </div>
      </footer>
    </>
  );
}
