import React, { useContext, useEffect, useState } from 'react';
import { GlobalContext, CONF, Dashboard, PKG, IMG } from '../config/imports';
import Button from 'react-bootstrap/Button';

export default function Public() {
  const { checkLogin, loading } = useContext(GlobalContext);
  const [logged, setLogged] = useState(false);

  useEffect(() => {
    const status = checkLogin();
    setLogged(status);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (loading) return <PKG.Loading />;
  if (logged) return <Dashboard />;

  const wrapBox = () => {
    const niceTexttoDisplay = () => {
      return (
        <div className='wpMax600 mt-1'>
          <p className='fs10 mb-0 fw600 ffSoft textContrastColor'>
            Acesso restrito
          </p>
          <section className='mb-4'>
            <a
              href='/login'
              rel='noreferrer'
              aria-label='Faça login'
              className='fw600 cLink'
            >
              Faça login
            </a>
          </section>
          <h1 className='fs12 fw700 ffSoft textContrastColor'>
            Temos um app exclusivo
          </h1>
          <h2 className='fs12 fw600'>
            Acompanhe em tempo real o seu processo de financiamento
          </h2>
          <Button
            size='sm'
            className='btn btn-primary mt-3'
            onClick={() => {
              const el = document.getElementById('saibamais');
              el.classList.toggle('divHide');
            }}
          >
            saiba mais
          </Button>
          <div
            id='saibamais'
            className='pt-1 divHide'
          >
            <p className='fs10'>
              Aqui você é atendido por um especialista em financiamento
              imobiliário, que vai te ajudar a encontrar a melhor solução para o
              seu caso.
            </p>
            <h2 className='fs10 fw600 mt-3'>
              Se você é corretor ou imobiliária, temos um acesso especial para
              você:{' '}
              <span className='fw400'>
                com o nosso dashboard, você pode acompanhar o processo de
                financiamento dos seus clientes.
              </span>
            </h2>
          </div>
        </div>
      );
    };

    return (
      <PKG.BoxTextImageInside
        image={IMG.img_news}
        text={niceTexttoDisplay()}
        clsnm='py-3'
      />
    );
  };

  return (
    <PKG.Wrapper
      conf={CONF}
      text={wrapBox()}
      clsnmTextHeader='fs18 textContrastLow'
    />
  );
}
