import CONF from '.';

export function metaInfo(conf) {
  return {
    type: CONF.meta.type,
    description: CONF.meta.description,
    keywords: CONF.meta.keywords,
    author: CONF.meta.author,
    open: {
      title: CONF.meta.title,
      description: CONF.meta.description,
      image: CONF.meta.imageLink,
      imageWidth: CONF.meta.imageWidth,
      imageHeight: CONF.meta.imageHeight,
      imageType: CONF.meta.imageType,
      url: `https://www.${CONF.domain}`,
      type: 'website',
    },
    twitter: {
      card: 'summary_large_image',
      title: CONF.meta.title,
      description: CONF.meta.description,
      image: CONF.meta.imageLink,
    },
    robots: CONF.meta.robots,
  };
}

const META = metaInfo(CONF);

export function createSchema(schema) {
  const scriptTag = document.createElement('script');
  scriptTag.type = 'application/ld+json';
  scriptTag.text = JSON.stringify(schema);
  document.head.appendChild(scriptTag);
  return () => {
    document.head.removeChild(scriptTag);
  };
}

export function defineSchema() {
  const jsonLd = {
    '@context': 'https://schema.org',
    '@type': META.type || 'Organization',
    name: `${CONF.title} ${CONF.headline}`,
    alternateName: CONF.tenant,
    description: CONF.description,
    image: CONF.links.logo,
    url: CONF.links.site,
    email: CONF.setup.email,
    telephone: `+55${CONF.setup.phone}`,
    areaServed: 'BR',
    brand: CONF.brand,
    slogan: CONF.headline,
    hasCertification: {
      '@type': 'Certification',
      name: 'CA-300',
      description: 'Certificação de Agente de Crédito',
      issuedBy: {
        '@type': 'Organization',
        name: 'ABECIP',
        url: 'https://www.abecip.org.br/',
      },
    },
    address: {
      '@type': 'PostalAddress',
      addressLocality: 'Taubaté-SP',
      addressRegion: 'SP',
      postalCode: '12000-000',
      streetAddress: 'Centro',
    },
    aggregateRating: {
      '@type': 'AggregateRating',
      ratingValue: 99,
      bestRating: 100,
      ratingCount: 5891,
    },
  };

  return createSchema(jsonLd);
}

export function defineMeta() {
  document.title = `${CONF.tenant} ${CONF.headline}`;

  const currentYear = new Date().getFullYear();

  const setElement = (selector, type, attributes) => {
    const element =
      document.querySelector(selector) || document.createElement(type);
    Object.entries(attributes).forEach(([key, value]) =>
      element.setAttribute(key, value),
    );
    document.head.appendChild(element);
  };

  setElement('link[rel="icon"]', 'link', {
    rel: 'icon',
    type: 'image/x-icon',
    href: CONF.favicon,
  });
  setElement('link[rel="apple-touch-icon"]', 'link', {
    rel: 'apple-touch-icon',
    type: 'image/x-icon',
    href: `/img/brand/${CONF.brand}/logo512.png`,
  });

  const metaTags = [
    { name: 'description', content: META.description },
    { name: 'keywords', content: META.keywords },
    { name: 'author', content: META.author },
    { name: 'publisher', content: META.author },
    { name: 'robots', content: META.robots },
    { name: 'viewport', content: 'width=device-width, initial-scale=1' },
    { name: 'googlebot', content: 'index, follow' },
    { name: 'rating', content: 'general' },
    { name: 'revisit-after', content: '1 days' },
    { name: 'language', content: 'pt-BR' },
    { name: 'country', content: 'Brazil' },
    { name: 'geo.region', content: 'BR-SP' },
    { name: 'copyright', content: `${currentYear} ${CONF.tenant}` },
    {
      name: 'robots',
      content:
        'index, follow, max-image-preview:large, max-snippet:-1, max-video-preview:-1',
    },
    { name: 'Content-Type', content: 'text/html; charset=utf-8' },
  ];

  metaTags.forEach((tag) =>
    setElement(`meta[name="${tag.name}"]`, 'meta', tag),
  );

  setElement('link[rel="canonical"]', 'link', {
    rel: 'canonical',
    href: META.open.url,
  });

  const ogTags = [
    { property: 'og:title', content: META.open.title },
    { property: 'og:description', content: META.open.description },
    { property: 'og:image', content: META.open.image },
    { property: 'og:image:width', content: META.open.imageWidth },
    { property: 'og:image:height', content: META.open.imageHeight },
    { property: 'og:image:type', content: META.open.imageType },
    { property: 'og:url', content: META.open.url },
    { property: 'og:type', content: META.open.type },
    { property: 'og:locale', content: 'pt_BR' },
    { property: 'og:site_name', content: CONF.tenant },
  ];

  ogTags.forEach((tag) =>
    setElement(`meta[property="${tag.property}"]`, 'meta', tag),
  );

  const twitterTags = [
    { name: 'twitter:card', content: META.twitter.card },
    { name: 'twitter:title', content: META.twitter.title },
    { name: 'twitter:description', content: META.twitter.description },
    { name: 'twitter:image', content: META.twitter.image },
  ];

  twitterTags.forEach((tag) =>
    setElement(`meta[name="${tag.name}"]`, 'meta', tag),
  );
}

export function addMeta(name, content) {
  const meta = document.createElement('meta');
  meta.name = name;
  meta.content = content;
  document.head.appendChild(meta);
}
