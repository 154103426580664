import React, { useContext, useEffect, useState } from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import '../../assets/css/app/nav.css';

import {
  CONF,
  PKG,
  IMG,
  GlobalContext,
  // RenderSectionButton,
} from '../../config/imports';

export default function NavBar() {
  const { checkLogin, setShowSection, navigate, logout } =
    useContext(GlobalContext);

  const { theme, changeTheme } = useContext(PKG.PackageContext);
  const [logged, setLogged] = useState(false);

  useEffect(() => {
    const status = checkLogin();
    setLogged(status);
  }, [logged, checkLogin]);

  return (
    <nav className='navbar100'>
      <div className='navbar'>
        <>
          <div className='navbarLogo'>
            <img
              src={CONF.images[theme].logotext}
              className='navLogo logoTXT'
              title={CONF.tenant}
              alt={`${CONF.tenant} logo`}
              onClick={() => {
                navigate('/');
                setShowSection('news');
              }}
            />
            <img
              src={CONF.images[theme].logo}
              className='navLogo logoXS'
              title={CONF.tenant}
              alt={`${CONF.tenant} logo`}
              onClick={() => {
                navigate('/');
                setShowSection('news');
              }}
            />
          </div>
        </>
        <div className='d-flex flex-row align-items-center'>
          <div className='d-flex flex-row align-items-center'>
            <div className='d-flex flex-row justify-content-center align-items-center navUL'>
              <span className='divShow divHide500'>
                <PKG.LinkButton item={CONF.objLinks.simule} />
              </span>
              {logged ? <PKG.LinkButton item={CONF.objLinks.app} /> : null}
              {logged ? (
                <div className='ms-1 navbarMenu'>
                  <Dropdown>
                    <Dropdown.Toggle
                      variant=''
                      className='d-flex flex-row align-items-center'
                      id='dropdown-menu-navbar-toggle'
                      aria-label='dropdown'
                    >
                      <img
                        src={IMG.logo_menu}
                        alt=''
                        className='divHide divShow900 imgsvg'
                      />
              
                    </Dropdown.Toggle>
                    <Dropdown.Menu className='wpMin175'>
                      <Dropdown.Item onClick={() => setShowSection('account')}>
                        <span className='spNavMenuIcon'>
                          <img
                            src={IMG.logo_user}
                            alt='user'
                            className='imgsvg'
                          />
                        </span>
                        <span className='spNavMenuText'>Minha conta</span>
                      </Dropdown.Item>

                      <span className='divHide divShow500'>
                        <PKG.LinkButton item={CONF.objLinks.simule} />
                        <PKG.LinkButton item={CONF.objLinks.whatsapp} />
                        <PKG.LinkButton item={CONF.objLinks.mail} />
                        <hr />
                      </span>

                      <Dropdown.Item onClick={() => setShowSection('support')}>
                        <span className='spNavMenuIcon'>
                          <img
                            src={IMG.help_icon}
                            alt='help'
                            className='imgsvg'
                          />
                        </span>
                        <span className='spNavMenuText'>Suporte</span>
                      </Dropdown.Item>
                      <Dropdown.Item
                        onClick={() => {
                          setLogged(false);
                          logout();
                          navigate('/logout');
                        }}
                      >
                        <span className='spNavMenuIcon'>
                          <img
                            src={IMG.img_logout}
                            alt='logout'
                            className='imgsvg'
                          />
                        </span>
                        <span className='spNavMenuText'>Sair</span>
                      </Dropdown.Item>
                      <hr />
                      <PKG.ThemeSwitch />
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              ) : (
                <>
                  <PKG.LinkButton item={CONF.objLinks.login} />
                  <hr />
                  <PKG.ThemeSwitch
                    drop={true}
                    globalTheme={changeTheme}
                  />
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
}
