import React, { useContext, useState } from 'react';
import { CONF, end, GlobalContext, PKG } from '../../config/imports';
import '../../assets/css/app/blobviewer.css';

const BlobViewer = ({
  blobCode,
  blobType,
  blobIcon,
  blobName,
  docSim = false,
  cod_doc_sim = '',
  blobTitle = '',
  blobInfo = '',
  allowBlobView = true,
}) => {
  const { and, sim_docs } = end;
  const [url, setUrl] = useState(null);
  const [loading, setLoading] = useState(false);
  const { store } = useContext(GlobalContext);
  const { token } = store();

  const [showContent, setShowContent] = useState(false);

  const loadBlobContent = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const form = new FormData();
      if (docSim) {
        form.append('simulacao_codigo', cod_doc_sim);
      }
      form.append('codigo', blobCode);
      form.append('files_type', blobType);
      form.append('token', token);

      const url = docSim
        ? `${CONF.ORA}${sim_docs.read.doc}/`
        : `${CONF.ORA}${and.read.docs}/`;
      const response = await fetch(url, {
        method: 'POST',
        body: form,
      });

      const blob = await response.blob();
      const objectURL = URL.createObjectURL(blob);
      setUrl(objectURL);
    } catch (error) {
      console.error(error);
    }
    setLoading(false);
  };

  return (
    <div className='d-flex flex-column '>
      <div className='d-flex flex-row justify-content-start align-items-center py-2 '>
        <span className='me-2 breakAvoid'>{blobIcon}</span>

        {allowBlobView && (
          <>
            <PKG.IconsUI
              info={PKG.ui.action.magnifier}
              clsnm='svg14 cLink me-2 mousePointerHover '
              click={(e) => {
                if (!url) loadBlobContent(e);
              }}
            />
            <PKG.IconsUI
              info={showContent ? PKG.ui.expand.less : PKG.ui.expand.more}
              clsnm='svg16 cLink me-2 mousePointerHover '
              click={(e) => {
                if (!url && showContent === false) loadBlobContent(e);
                setShowContent(!showContent);
              }}
            />
          </>
        )}
        <div className='gocol align-items-start '>
          <span className='fs10 textContrastLow me-2  '>{blobTitle}</span>
          <span className='fs10 me-2 '>{blobName}</span>
          <span className='fs09 textContrastLow me-2 '>{blobInfo}</span>
        </div>
      </div>
      {allowBlobView && (
        <div
          className={`content-doc fs10 ffSoft  ${showContent ? '' : 'divHide'}`}
          id={`cont_doc_${blobCode}`}
        >
          {loading ? (
            'Carregando...'
          ) : url ? (
            blobType.includes('image') ? (
              <>
                <div className='my-2 fs10'>
                  <a
                    href={url}
                    download
                  >
                    <PKG.IconsUI
                      info={PKG.ui.action.download}
                      clsnm='svg14 cLink'
                    />
                    <span className='ms-1'>Baixar Imagem</span>
                  </a>
                </div>
                <img
                  src={url}
                  alt='img'
                  className='img_iframe'
                />
              </>
            ) : blobType === 'application/pdf' ? (
              <iframe
                title='PDF Viewer'
                src={url}
                className='pdf_iframe'
              />
            ) : (
              ''
            )
          ) : (
            ''
          )}
        </div>
      )}
    </div>
  );
};

export default BlobViewer;
