import axios from 'axios';

async function basePost (type, base, endpoint, body) {
  let postType = type === 'files' ? 'multipart/form-data' : 'application/json';
  const api = axios.create({
    baseURL: base,
    headers: {
      'Content-Type': postType,
    },
  });
  const token = sessionStorage.getItem('@App:token');
  api.defaults.headers.Authorization = token;
  body.token = token;

  const response = await api.post(endpoint, body);
  return response;
}

export async function post(base, endpoint, body) {
  return basePost('json', base, `${endpoint}/`, body);
}

export async function postFiles(base, endpoint, body) {
  return basePost('files', base, endpoint, body);
}
