import { useEffect, useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import { SCR, IconsUI, ui } from '..';

export default function CustomDropdown({
  arrayObj = [],
  defaultValue = 1,
  changeCallApi = null,
  updateParent = null,
  identification = null,
  value = 1,
  size = '',
  showLabel = '',
  customClass = 'm-0 mx-1',
  svgClass = ''
}) {
  let elementId = `drop_arrayObj_${identification || SCR.Utils.randomCode(8)}`;

  const tabela = arrayObj[0].TABELA;

  const [dropColor, setDropColor] = useState(arrayObj[defaultValue - 1].CODIGO);
  const [dropName, setDropName] = useState(arrayObj[defaultValue - 1].NOME);

  const handleSelect = async (id) => {
    if (changeCallApi !== null) changeCallApi(id);
    if (updateParent !== null) updateParent();
    
    setDropColor(arrayObj.find((item) => item.ID === id).CODIGO);
    setDropName(arrayObj.find((item) => item.ID === id).NOME);

    const drop = document.getElementById(`drop_menu_out_arrayObj_${elementId}`);
    drop.classList.remove('show');
  };

  useEffect(() => {
    setDropColor(arrayObj.find((item) => item.ID === value).CODIGO);
    setDropName(arrayObj.find((item) => item.ID === value).NOME);
  }
  , [value, arrayObj]);

  return (
    <Dropdown className={customClass}>
      <Dropdown.Toggle
        variant=''
        size={size}
        id={`drop_arrayObj_${elementId}`}
        className='drop-arrayObj '
      >
        <IconsUI
          info={ui[tabela][dropName]}
          clsnm={`svg${dropColor} svg12 ${svgClass}`}
          tooltip={`${showLabel}${showLabel ? ' ' : ''}${dropName}`}
        />
        {showLabel && (
          <span className='ffSoft fs10 textContrast ms-1'>
            {showLabel} {dropName}
          </span>
        )}
      </Dropdown.Toggle>

      <Dropdown.Menu
        size={size}
        className='drop-menu-out'
        id={`drop_menu_out_arrayObj_${elementId}`}
      >
        {arrayObj.map((item, index) => (
          <span
            data-rr-ui-dropdown-item
            role='button'
            className={`drop-menu-out-line p-1 faCenter cWhite bg-${item.CODIGO}`}
            key={`drop_arrayObj_${elementId}_${item.ID}_${index}_badge`}
            onClick={() => handleSelect(item.ID)}
          >
            {item.NOME}
          </span>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
}
