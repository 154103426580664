import { useContext, useState } from 'react';
import Badge from 'react-bootstrap/Badge';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Spinner from 'react-bootstrap/Spinner';
import Swal from 'sweetalert2';
import {
  api,
  end,
  PKG,
  ProcessoModal,
  SimulacaoModal,
} from '../../../config/imports';

export default function SimulacaoLine({ obj, upd }) {
  const { theme } = useContext(PKG.PackageContext);

  const { createLinkWhatsapp, curr, dataBr, isTrue } = PKG.SCR.Utils;
  const { sim } = end;
  const { post } = api;
  const [showSimulacao, setShowSimulacao] = useState(false);
  const [showProcessoCreate, setShowProcessoCreate] = useState(false);
  const [active, setActive] = useState();
  const [loading, setLoading] = useState();

  const [modalSimulacaoFull, setModalSimulacaoFull] = useState(false);

  const handleCloseSimulacao = () => setShowSimulacao(false);
  const handleShowSimulacao = () => setShowSimulacao(true);

  const handleSetSimulacaoModalFull = () => {
    const newstate = !modalSimulacaoFull;
    setModalSimulacaoFull(newstate);
  };

  const handleCloseProcessoCreate = () => setShowProcessoCreate(false);
  const handleShowProcessoCreate = () => {
    setShowProcessoCreate(true);
  };

  const handleObj = (newObj) => {
    obj = newObj;
  };

  const get_older = (obj) => {
    if (obj.SIM_COMPLETA && obj.SIM_COMPOR) {
      const nascDate = new Date(obj.SIM_NASCIMENTO);
      const outroNascDate = new Date(obj.SIM_OUTRO_NASCIMENTO);
      const olderDate = nascDate < outroNascDate ? outroNascDate : nascDate;
      return dataBr(olderDate);
    }
    return dataBr(obj.SIM_NASCIMENTO);
  };

  function simpleDescription(description) {
    const simplifiedDescriptions = {
      'Aquisição de Imóvel Novo': 'Imóvel novo',
      Construção: 'Construção',
      'Reforma ou Ampliação': 'Reforma',
      'Aquisição de Imóvel Usado': 'Imóvel usado',
      'Aquisição de Terreno': 'Terreno',
      'Empréstimo Garantido por Imóvel': 'Empréstimo',
      'Imóveis CAIXA': 'Imóveis CAIXA',
    };

    return simplifiedDescriptions[description] || description; // Returns simplified description or the original if not found
  }

  obj.tipo = obj.SIM_COMPLETA ? 'Completa' : 'Simplificada';

  obj.imovel = {
    valor: parseFloat(obj.SIM_IMOVEL_VALOR),
    avaliacao: parseFloat(obj.SIM_IMOVEL_VALOR),
    cidade: obj.SIM_IMOVEL_CIDADE,
  };

  obj.perfil = {
    data_criado: new Date(obj.SIM_CREATED).toLocaleDateString('pt-BR', {
      day: '2-digit',
      month: '2-digit',
      year: '2-digit',
    }),
    hora_criado: new Date(obj.SIM_CREATED).toLocaleTimeString('pt-BR', {
      hour: '2-digit',
      minute: '2-digit',
      hour12: false,
    }),
    whatsapp: obj.SIM_WHATSAPP
      ? createLinkWhatsapp(obj.SIM_WHATSAPP)
      : sim.USER_TELEFONE
      ? createLinkWhatsapp(obj.USER_TELEFONE)
      : '',
    compor: obj.SIM_COMPOR,
    relacionamento: obj.SIM_RELACIONAMENTO,
    mais_velho: get_older(obj),
    cliente: {
      nome: obj.USER_NOME,
      cpf: obj.USER_CPF,
      nascimento: obj.SIM_NASCIMENTO,
      comprovacao: obj.SIM_COMPROVAR,
      renda: parseFloat(obj.SIM_RENDA),
      fgts: parseFloat(obj.SIM_FGTS),
      recursos: parseFloat(obj.SIM_RECURSOS),
    },
    outro: {
      nome: obj.SIM_OUTRO_NOME,
      cpf: obj.SIM_OUTRO_CPF,
      nascimento: obj.SIM_OUTRO_NASCIMENTO,
      comprovacao: obj.SIM_OUTRO_COMPROVAR,
      renda: parseFloat(obj.SIM_OUTRO_RENDA || 0),
      fgts: parseFloat(obj.SIM_OUTRO_FGTS || 0),
      recursos: parseFloat(obj.SIM_OUTRO_RECURSOS || 0),
    },
    beneficiado: isTrue([obj.SIM_BENEFICIADO]),
    possui_imovel: isTrue([obj.SIM_POSSUI_IMOVEL]),
    servidor_publico: isTrue([obj.SIM_SERVIDOR_PUBLICO]),
    dependentes: isTrue([obj.SIM_DEPENDENTES]),
    anos_fgts: isTrue([obj.SIM_ANOS_FGTS]),
    clt: isTrue([obj.SIM_CLT]),
    possui_imovel_cidade: obj.SIM_POSSUI_IMOVEL_CIDADE,
  };

  obj.resumo = {
    rendas: parseFloat(obj.perfil.cliente.renda + obj.perfil.outro.renda),
    fgts: parseFloat(obj.perfil.cliente.fgts + obj.perfil.outro.fgts),
    recursos: parseFloat(
      obj.perfil.cliente.recursos + obj.perfil.outro.recursos,
    ),
  };

  obj.resumo.entrada = parseFloat(obj.resumo.fgts + obj.resumo.recursos);
  obj.resumo.financiado = parseFloat(obj.imovel.valor - obj.resumo.entrada);

  obj.proc = {
    perfil: obj.perfil,
    imovel: obj.imovel,
    resumo: obj.resumo,
  };

  obj.proc.fin = {
    banco: '',
    tabela: '',
    taxa: '',
    linha: '',
    operacao: obj.SIM_IMOVEL_DESCRICAO,
    compartilhar: false,
    informacoes: obj.SIM_INFORMACOES,
    headline: '',
  };

  obj.proc.atendimento = {
    corretor: '',
    imobiliaria: '',
    correspondente: '',
  };

  obj.proc.user = {
    nome: obj.USER_NOME,
    cpf: obj.USER_CPF,
    whatsapp: obj.USER_TELEFONE,
    email: obj.USER_EMAIL,
  };

  const update_active = async (event) => {
    event.preventDefault();
    let codigo = obj.SIM_CODIGO;
    let updated_active = active ? 0 : 1;

    try {
      setLoading(true);
      await post(sim.update.active, {
        codigo,
        active: updated_active,
      });
      setActive(updated_active);
    } catch (error) {
      console.error(error);
    }
    setLoading(false);
  };

  const delete_simulacao = async (event) => {
    event.preventDefault();
    const delete_sim = async () => {
      try {
        setLoading(true);
        await post(sim.delete, {
          codigo: obj.SIM_CODIGO,
        });
      } catch (error) {
        console.error(error);
      }
      upd();
      setLoading(false);
    };

    Swal.fire({
      title: 'Tem certeza?',
      text: 'Você deseja deletar essa simulação? Essa ação não pode ser desfeita!',
      icon: 'warning',
      showDenyButton: true,
      showCancelButton: false,
      confirmButtonColor: '#c41000',
      confirmButtonText: 'Sim, deletar',
      iconColor: '#c41000',
      denyButtonColor: '#6cbe00',
      denyButtonText: 'Não',
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire('Ok, deletado!', '', 'success');
        delete_sim();
      } else if (result.isDenied) {
        Swal.fire('Tudo bem, vou manter a simulação aqui', '', 'info');
      }
    });
  };

  const button_on = () => {
    if (loading)
      return (
        <Spinner
          animation='border'
          variant='success'
          size='sm'
        />
      );
    if (active == null) {
      setActive(obj.SIM_ACTIVE);
    }
    if (active)
      return (
        <Badge
          className='fw200 wp35 divHide700'
          bg='success'
        >
          ON
        </Badge>
      );
    return (
      <Badge
        className='fw200 wp35 cBlack divHide700'
        bg='light'
      >
        OFF
      </Badge>
    );
  };

  const badgeCompor = () => {
    if (obj.SIM_COMPLETA && obj.SIM_COMPOR) {
      return (
        <Badge
          className='p-2 divHide700'
          pill
          bg={theme}
          text='info'
        >
          <PKG.IconsUI
            info={PKG.ui.user.multi}
            clsnm='svg14'
            tooltip='Renda múltipla'
          />
        </Badge>
      );
    }
    return (
      <Badge
        className='p-2 divHide700' 
        pill
        bg={theme}
        text='warning'
      >
        <PKG.IconsUI
          info={PKG.ui.user.single}
          clsnm='svg14'
          tooltip='Renda única'
        />
      </Badge>
    );
  };

  const badgeFull = () => {
    if (obj.SIM_COMPLETA) {
      return (
        <Badge
          className='p-2 divHide700'
          pill
          bg={theme}
          text='success'
          tooltip='Simulação Completa'
        >
          <PKG.IconsUI
            info={PKG.ui.letter.f}
            clsnm='svg14'
            tooltip='Simulação Completa'
          />
        </Badge>
      );
    }
    return (
      <Badge
        className='p-2 divHide700'
        pill
        bg={theme}
        text='info'
      >
        <PKG.IconsUI
          info={PKG.ui.letter.s}
          clsnm='svg14'
          tooltip='Simulação Simplificada'
        />
      </Badge>
    );
  };

  return (
    <tr className='tableSimulacoes fs10'>
      <td>{obj.USER_NOME}</td>
      <td className='wpMin150'>
        <a href={obj.perfil.whatsapp}>
          <span className='fs16 cCyan mx-1'>
            <PKG.IconsUI
              info={PKG.ui.social.whatsapp}
              clsnm='svg09 cGreen'
            />
          </span>
          {obj.SIM_WHATSAPP}
        </a>
      </td>
      <td className='divHide1000'>{obj.USER_EMAIL}</td>
      <td className='divHide800'>{obj.SIM_IMOVEL_CIDADE}</td>
      <td className='divHide700'>{simpleDescription(obj.SIM_IMOVEL_DESCRICAO)}</td>
      <td className='divHide650'>{curr(obj.imovel.valor)}</td>
      <td className='divHide1000'>
        {obj.perfil.data_criado} {obj.perfil.hora_criado}
      </td>
      <td>{badgeFull()}</td>
      <td>{badgeCompor()}</td>
      <td
        className='me-1 mousePointerHover'
        data-toggle='tooltip'
        title={obj.SIM_ACTIVE ? 'Desativar' : 'Ativar'}
        onClick={update_active}
      >
        {button_on()}
      </td>

      <td>
        <PKG.IconsUI
          info={PKG.ui.etapas.simulacao}
          clsnm='svg14 mx-2 cGreen mousePointerHover'
          tooltip='Abrir simulação'
          click={handleShowSimulacao}
        />
      </td>
      <td>
        {obj.PROCESSO_CODIGO ? (
          <PKG.IconsUI
            info={PKG.ui.folder.open}
            clsnm='svg14 mx-2 cGreen mousePointerHover'
            tooltip='Abrir processo'
            link={`/processo/${obj.PROCESSO_CODIGO}`}
          />
        ) : (
          <PKG.IconsUI
            info={PKG.ui.misc.add_database}
            clsnm='svg14 mx-2 cGreen mousePointerHover'
            tooltip='Criar novo processo'
            click={handleShowProcessoCreate}
          />
        )}
      </td>
      <td>
        <PKG.IconsUI
          info={PKG.ui.action.delete.trash}
          clsnm={`svg14 mx-2 mousePointerHover ${
            obj.PROCESSO_CODIGO ? 'cGray300' : 'cRed'
          }`}
          tooltip='Deletar simulação'
          click={obj.PROCESSO_CODIGO ? null : delete_simulacao}
        />
      </td>

      <>
        <Modal
          show={showSimulacao}
          size='lg'
          fullscreen={modalSimulacaoFull}
          data-bs-theme={theme}
          aria-labelledby='contained-modal-title-vcenter'
          centered
          onHide={handleCloseSimulacao}
        >
          <Modal.Header closeButton>
            <Modal.Title className='fs16 fw300'>
              <div className='d-flex flex-row justify-content-between'>
                <span>
                  Simulação<span className='fw100 mx-2'>|</span>
                  <span className='fw600 textContrastColor'>{obj.USER_NOME}</span>
                </span>
                <span className='ms-3 fs10 fw500'>
                  {modalSimulacaoFull ? (
                    <Badge
                      className='fw200 ms-1 mousePointerHover'
                      bg='success'
                      onClick={handleSetSimulacaoModalFull}
                    >
                      Modo Fullscreen
                    </Badge>
                  ) : (
                    <Badge
                      className={`fw200 ms-1 mousePointerHover textMenu`}
                      bg='secondary'
                      onClick={handleSetSimulacaoModalFull}
                    >
                      Modo Janela
                    </Badge>
                  )}
                </span>
              </div>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <SimulacaoModal
              obj={obj}
              on={active}
              changeActive={update_active}
              buttonON={button_on}
              load={loading}
              del={delete_simulacao}
              full={modalSimulacaoFull}
            />
          </Modal.Body>
        </Modal>
      </>
      <>
        <Modal
          show={showProcessoCreate}
          size='lg'
          aria-labelledby='contained-modal-title-vcenter'
          centered
          onHide={handleCloseProcessoCreate}
        >
          <Modal.Header closeButton>
            <Modal.Title>Novo Processo</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <ProcessoModal
              obj={obj}
              on={active}
              changeActive={update_active}
              buttonON={button_on}
              load={loading}
              nobj={handleObj}
            />
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant='light'
              onClick={handleCloseProcessoCreate}
            >
              Sair
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    </tr>
  );
}
