import { useState } from 'react';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Swal from 'sweetalert2';
import { IMaskInput } from 'react-imask';
import '../assets/css/components/faleconoscoform.css';

export default function FaleConoscoForm({
  CONF,
  createLead,
  clsnmCallForm = '',
  title = 'Fale conosco agora',
  colorMode = 'dark',
}) {
  const [form, setForm] = useState({
    email: '',
    whatsapp: '',
    nome: '',
    assunto: '',
    termos: false,
  });

  const handleForm = (e) => {
    setForm({ ...form, [e.target.id]: e.target.value });
  };
  const handleSend = async () => {
    if (
      form.email === '' ||
      form.whatsapp === '' ||
      form.nome === '' ||
      form.assunto === '' ||
      !form.termos
    ) {
      Swal.fire({
        title: 'Ops!',
        text: 'Você precisa preencher todos os campos e concordar com os termos de uso',
        confirmButtonColor: CONF.colors.bs.green,
        confirmButtonText: 'Ok, entendi!',
        background: CONF.colors.grafitte,
        color: CONF.colors.grey,
        iconColor: CONF.colors.bs.green,
      });
      return;
    } else {
      await createLead({
        name: form.nome,
        email: form.email,
        phone: form.whatsapp,
        message: form.assunto,
      });
      Swal.fire({
        title: 'Obrigado!',
        text: 'Seus dados foram enviados com sucesso para um especialista. Em breve entraremos em contato.',
        confirmButtonColor: CONF.colors.bs.gray[600],
        confirmButtonText: 'Ok',
        background: CONF.colors.grafitte,
        color: CONF.colors.grey,
        iconColor: CONF.colors.bs.green,
      });
      setForm({
        email: '',
        whatsapp: '',
        nome: '',
        assunto: '',
      });
    }
  };

  const setColor = () => {
    switch (colorMode) {
      case 'dark':
        return 'formCallDark';
      default:
        return 'formCallLight';
    }
  };

  return (
    <Form className={`${setColor()} ${clsnmCallForm} mAuto`}>
      <h1 className='fs26 fw600 py-3 text-center'>{title}</h1>
      <Form.Group className='mb-3'>
        <Form.Label htmlFor='email'>Email</Form.Label>
        <Form.Control
          required
          type='email'
          placeholder='nome@exemplo.com'
          value={form.email}
          autoFocus
          id='email'
          name='email'
          onChange={(e) => handleForm(e)}
        />
      </Form.Group>
      <Form.Group className='mb-3'>
        <Form.Label htmlFor='whatsapp'>Whatsapp</Form.Label>
        <Form.Control
          type='text'
          as={IMaskInput}
          mask='(00) 00000-0000'
          id='whatsapp'
          name='whatsapp'
          required
          value={form.whatsapp}
          onChange={(e) => handleForm(e)}
        />
      </Form.Group>
      <Form.Group className='mb-3'>
        <Form.Label htmlFor='nome'>Nome</Form.Label>
        <Form.Control
          type='text'
          placeholder='Nome completo'
          value={form.nome}
          required
          id='nome'
          name='nome'
          onChange={(e) => handleForm(e)}
        />
      </Form.Group>
      <Form.Group className='mb-3'>
        <Form.Label htmlFor='assunto'>Assunto</Form.Label>
        <Form.Control
          as='textarea'
          required
          value={form.assunto}
          rows={5}
          id='assunto'
          name='assunto'
          onChange={(e) => handleForm(e)}
        />
      </Form.Group>
      <Form.Group className='mb-3'>
        <Form.Check
          type='checkbox'
          required
          checked={form.termos}
          id='termos'
          label='Li e concordo com os termos de uso'
          onChange={(e) => setForm({ ...form, termos: e.target.checked })}
        />
      </Form.Group>
      <div>
        <p className='fs-10'>
          Ao clicar em <b>Solicitar especialista</b>, você concorda com os
          nossos{' '}
          <a
            href='/privacidade'
            target='_blank'
            rel='noreferrer'
          >
            termos de uso e política de privacidade
          </a>
        </p>
      </div>
      <div className='text-center'>
        <Button
          variant='primary'
          onClick={handleSend}
        >
          Solicitar especialista
        </Button>
      </div>
    </Form>
  );
}
