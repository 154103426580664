import { useContext } from 'react';
import { GlobalContext, PKG } from '../../../config/imports';

function NewsTimelineItem({ item }) {
  const { a } = useContext(GlobalContext);
  const { prioridades } = a;

  const newsIcon = (item) => {
    switch (item.TABELA) {
      case 'ANDAMENTOS':
        return (
          <>
            <PKG.IC.RiMenuAddLine className='svg12 me-2' />
            <span>
            {item.ANDAMENTO_TIPO === 'tarefa' ? 'Nova' : 'Novo'} <span className='cPurple'>{item.ANDAMENTO_TIPO}</span> cadastrado por {item.ANDAMENTO_USER_NOME} no{' '}
              <a
                href={`/processo/${item.PROCESSO_CODIGO}`}
                target='_self'
                rel='noreferrer'
              >
                processo
              </a>{' '}
              de {item.NOME}
            </span>
          </>
        );
      case 'USERS':
        return (
          <>
            <PKG.IC.FaUser className='svg10 me-2' />
            <span>Novo usuário: {item.NOME}</span>
          </>
        );
      case 'SIMULACOES':
        return (
          <>
            <PKG.IC.FaUser className='svg12 me-2' />
            <span>Nova simulação recebida de {item.NOME}</span>
          </>
        );
      case 'TAREFA':
        return (
          <>
            <PKG.IC.GoTasklist className='svg12 me-2' />
            {/* {item} */}
          </>
        );
      case 'messages':
        return (
          <>
            <PKG.IC.BiSolidMessageRoundedEdit className='svg12 me-2' />
            <span>Nova mensagem de {item.NOME}</span>
            {/* {item} */}
          </>
        );
      case 'PROCESSOS':
        return (
          <>
            <PKG.IC.TbTimeline className='svg12 me-2' />
            <span>
              Novo{' '}
              <a
                href={`/processo/${item.PROCESSO_CODIGO}`}
                target='_self'
                rel='noreferrer'
              >
                processo
              </a>{' '}
              para {item.NOME}
            </span>
            {/* {item} */}
          </>
        );
      default:
        return '';
    }
  };

  const getColor = (prioridade_id) => {
    let color = prioridades.find((item) => item.ID === prioridade_id);
    return color ? color.ic : 'primary';
  };

  const criado = new Date(item.CREATED).toLocaleDateString();

  return (
    <li className='liItem'>
      <div className='liContent'>
        <PKG.IC.FaCircle
          className={`svg05 me-3 svg-${getColor(item.PRIORIDADE_ID - 1)}`}
        />
        <span className='fs07 me-2 cGrey'>{criado}</span>
        <span className='card-subtitle fs08'>{newsIcon(item)}</span>
      </div>
    </li>
  );
}

export default NewsTimelineItem;
