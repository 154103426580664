import { IconsUI } from  '..';

export default function LinkButton({item}) {

  const pkgIcon = () => {
    return (
      <IconsUI
        info={item.icon}
        clsnm={item.icon.class || ''}
        tooltip={item.icon.tooltip || ''}
        click={item.icon.click || null}
        link={item.a.href || null}
        linkTargetToBlank={item.a.targetBlank}
        linkAriaLabel={item.a.aria || item.icon.tooltip || 'link'}
        linkRel={item.a.rel || 'noreferrer'}
        linkClass={item.a.class || ''}
      />
    );
  };
  const pkgText = () => {
    return (
      <span className={`d-flex flex-row ms-2 ${item.text.class}`}>
        {item.text.content}
      </span>
    );
  };

  return (
    <>
      {item.text ? (
        item.divClass ? (
          <div className={item.divClass}>
            {item.icon ? pkgIcon() : null}
            {item.text ? pkgText() : null}
          </div>
        ) : (
          <>
            {item.icon ? pkgIcon() : null}
            {item.text ? pkgText() : null}
          </>
        )
      ) : item.icon ? (
        pkgIcon()
      ) : null}
    </>
  );
}
