export const variacoesCEF = {
  tr: {
    id: 'variacoes_tr',
    value: 1.1,
    name: 'TR 12 meses',
  },
  poup: {
    id: 'variacoes_poup',
    value: 7.18,
    name: 'Poupança 12 meses',
  },
  ipca: {
    id: 'variacoes_ipca',
    value: 3.93,
    name: 'IPCA 12 meses',
  },
  fixa: {
    id: 'variacoes_fixa',
    value: 11.5,
    name: 'Fixa',
  },
  selic: {
    id: 'variacoes_selic',
    value: 10.5,
    name: 'Selic',
  },
  seguro: {
    id: 'variacoes_seguro',
    value: 0.02,
    name: 'Seguro',
  },
  taxa_admin: {
    id: 'variacoes_taxa_admin',
    value: 25,
    name: 'Taxa Admin',
  },
  maxSac: {
    id: 'variacoes_maxSac',
    value: 420,
    name: 'Máx. Parcelas SAC',
  },
  maxPrice: {
    id: 'variacoes_maxPrice',
    value: 360,
    name: 'Máx. Parcelas Price',
  },
};

export const faixas = {
  mcmv_0: {
    id: 'faixas_mcmv_0',
    name: 'MCMV 0',
    renda: 2000,
    imovel: 170000,
    taxa: 4.5,
    variacao: null,
  },
  mcmv_1: {
    id: 'faixas_mcmv_1',
    name: 'MCMV 1',
    renda: 2640,
    imovel: 264000,
    taxa: 4.75,
    variacao: null,
  },
  mcmv_2: {
    id: 'faixas_mcmv_2',
    name: 'MCMV 2',
    renda: 3200,
    imovel: 264000,
    taxa: 5.25,
    variacao: null,
  },
  mcmv_3: {
    id: 'faixas_mcmv_3',
    name: 'MCMV 3',
    renda: 3800,
    imovel: 264000,
    taxa: 6,
    variacao: null,
  },
  mcmv_4: {
    id: 'faixas_mcmv_4',
    name: 'MCMV 4',
    renda: 4400,
    imovel: 264000,
    taxa: 7,
    variacao: null,
  },
  mcmv_5: {
    id: 'faixas_mcmv_5',
    name: 'MCMV 5',
    renda: 8000,
    imovel: 350000,
    taxa: 8.16,
    variacao: null,
  },
  sbpe_tr: {
    id: 'faixas_sbpe_tr',
    name: 'SBPE TR',
    renda: 2000,
    imovel: 10000,
    taxa: 9.99,
    variacao: 'tr',
  },
  sbpe_poup: {
    id: 'faixas_sbpe_poup',
    name: 'SBPE Poupança',
    renda: 2000,
    imovel: 10000,
    taxa: 3.99,
    variacao: 'poup',
  },
  sbpe_ipca: {
    id: 'faixas_sbpe_ipca',
    name: 'SBPE IPCA',
    renda: 2000,
    imovel: 10000,
    taxa: 5.3,
    variacao: 'ipca',
  },
  sbpe_fixa: {
    id: 'faixas_sbpe_fixa',
    name: 'SBPE Fixa',
    renda: 2000,
    imovel: 10000,
    taxa: 11.5,
    variacao: null,
  },
  santander: {
    id: 'faixas_santander',
    name: 'Santander',
    renda: 2000,
    imovel: 10000,
    taxa: 12,
    variacao: null,
  },
  bradesco: {
    id: 'faixas_bradesco',
    name: 'Bradesco',
    renda: 2000,
    imovel: 10000,
    taxa: 12,
    variacao: null,
  },
  itau: {
    id: 'faixas_itau',
    name: 'Itaú',
    renda: 2000,
    imovel: 10000,
    taxa: 12,
    variacao: null,
  },
  brasil: {
    id: 'faixas_brasil',
    name: 'Banco do Brasil',
    renda: 2000,
    imovel: 10000,
    taxa: 12,
    variacao: null,
  },
  inter_tr: {
    id: 'faixas_inter_tr',
    name: 'Banco Inter',
    renda: 2000,
    imovel: 10000,
    taxa: 11.5,
    variacao: 'tr',
  },
};
