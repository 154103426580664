import {
  services,
  IMG,
} from '../../config/imports';

export default function RenderSectionButton({ section, classn, clk }) {

  switch (section) {
    case 'simulacoes':
      if (services.Authorize(7))
        return (
          <div
            onClick={clk}
            className={classn}
          >
            <img
              src={IMG.img_simulacao}
              alt='button-simulacoes'
            />
            <span>simulações</span>
          </div>
        );
      break;
    case 'tasks':
      if (services.Authorize(5))
        return (
          <div
            onClick={clk}
            className={classn}
          >
            <img
              src={IMG.img_tasks}
              alt='button-tasks'
            />
            <span>tasks</span>
          </div>
        );
      break;

    case 'financiamentos':
      if (services.Authorize(5))
        return (
          <div
            onClick={clk}
            className={classn}
          >
            <img
              src={IMG.img_financ}
              alt='button-financiamentos'
            />
            <span>financiamentos</span>
          </div>
        );
      break;

      case 'protocolos':
      if (services.Authorize(5))
        return (
          <div
            onClick={clk}
            className={classn}
          >
            <img
              src={IMG.img_ticket}
              alt='button-financiamentos'
            />
            <span>protocolos</span>
          </div>
        );
      break;

    case 'processos':
      if (services.Authorize(7))
        return (
          <div
            onClick={clk}
            className={classn}
          >
            <img
              src={IMG.img_processos}
              alt='button-processos'
            />
            <span>processos</span>
          </div>
        );
      break;

    case 'clientes':
      if (services.Authorize(5))
        return (
          <div
            onClick={clk}
            className={classn}
          >
            <img
              src={IMG.img_clientes}
              alt='button-clientes'
            />
            <span>clientes</span>
          </div>
        );
      break;

    case 'admin':
      if (services.Authorize(4))
        return (
          <div
            onClick={clk}
            className={classn}
          >
            <img
              src={IMG.img_admin}
              alt='button-admin'
            />
            <span>administração</span>
          </div>
        );
      break;

    default:
      return '';
  }

}
