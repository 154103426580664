import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { PKG, CONF, api, end, GlobalContext } from '../config/imports';

export default function GlobalProvider({ children }) {
  const navigate = useNavigate();
  const pkgContext = useContext(PKG.AuthContext);
  // const { apoio, auth, users_roles } = end;

  const [loading, setLoading] = useState(false);
  const [showSection, setShowSection] = useState('news');
  const [a, setA] = useState([]);
  const [emailArchive, setEmailArchive] = useState();
  const [emailccArchive, setEmailccArchive] = useState();

  function store() {

    return {
      token: sessionStorage.getItem('@App:token'),
      email: sessionStorage.getItem('@App:email'),
      user: sessionStorage.getItem('@App:user'),
      codigo: sessionStorage.getItem('@App:c'),
      role: sessionStorage.getItem('@App:role'),
      role_id: sessionStorage.getItem('@App:i'),
      tenant: sessionStorage.getItem('@App:tc'),
      phone: sessionStorage.getItem('@App:telefone'),
      role_description: sessionStorage.getItem('@App:description'),
      ref: sessionStorage.getItem('@App:r'),
      sec: sessionStorage.getItem('@App:s'),
      dis: sessionStorage.getItem('@App:d'),
      pc: sessionStorage.getItem('@App:p'),
      apoio: JSON.parse(sessionStorage.getItem('@App:apoio')),
      app_roles: JSON.parse(sessionStorage.getItem('@App:app_roles'))
    };
  }

  useEffect(() => {
    const { apoio } = store();
    if (a.length === 0 && apoio !== null) {
      setup(apoio);
    }
  }, [a]);

  function checkLogin() {
    const { token, email, user } = store();
    if (!token || !email || !user) {
      return false;
    }
    return true;
  }

  const removeAspas = (str) => str.replace(/['"]+/g, '');

  async function login(userData) {
    const response = await pkgContext.login(
      CONF.ORA,
      end.auth.login,
      userData,
      [
        'i',
        'c',
        'tc',
        'p',
        'token',
        'role',
        'description',
        'r',
        'd',
        's',
        'user',
        'email',
        'telefone',
      ],
    );
    const { success } = response.data;

    if (success) {
      const { token } = store();
      await getApoio(token);
      const user_roles = await api.post(end.users_roles.read, { token });      
      sessionStorage.setItem(
        '@App:roles',
        removeAspas(JSON.stringify(user_roles.data)),
      );

      const app_roles = await api.post(end.roles.read, { token });
      const editedRoles = app_roles.data.map((item) => {
        const disallowArray = removeAspas(item.DISALLOW).split(',');
        if (disallowArray[0] === '') {
          return ({ ...item, DISALLOW: [] });
        }
        return ({ ...item, DISALLOW: disallowArray });
      });
      sessionStorage.setItem(
        '@App:app_roles',
        JSON.stringify(editedRoles),
      );
    }
    return response;
  }

  async function userData() {
    const response = await api.post(end.auth.user, {});
    return response.data;
  }

  async function logout(full = 0) {
    setLoading(true);
    await pkgContext.logout(CONF.ORA, end.auth.logout, full);
    setLoading(false);
  }

  function setup(data) {
    const ap = {
      faixas: data.filter((info) => info.TABELA === 'faixas'),
      variacoes: data.filter((info) => info.TABELA === 'variacoes'),
      bancos: data.filter((info) => info.TABELA === 'bancos'),
      operacoes: data.filter((info) => info.TABELA === 'operacoes'),
      prioridades: data.filter((info) => info.TABELA === 'prioridades'),
      andamentos: data.filter((info) => info.TABELA === 'andamentos'),
      status: data.filter((info) => info.TABELA === 'status'),
      documentos: data.filter((info) => info.TABELA === 'documentos'),
      tabelas: data.filter((info) => info.TABELA === 'tabelas'),
      imobiliarias: data.filter((info) => info.TABELA === 'imobiliarias'),
      corretores: data.filter((info) => info.TABELA === 'corretores'),
      correspondentes: data.filter((info) => info.TABELA === 'correspondentes'),
      support_categories: data.filter(
        (info) => info.TABELA === 'support_categories',
      ),
    };
    setA(ap);
  }

  async function getApoio(token) {
    const response = await api.post(end.apoio, { token });
    const data = await response.data;

    setup(data);
    sessionStorage.setItem('@App:apoio', JSON.stringify(data));
  }

  const context = {
    loading,
    setLoading,
    api,
    checkLogin,
    getApoio,
    navigate,
    showSection,
    setShowSection,
    login,
    logout,
    a,
    store,
    userData,
    emailArchive, setEmailArchive,
    emailccArchive, setEmailccArchive
  };

  return (
    <GlobalContext.Provider value={context}>{children}</GlobalContext.Provider>
  );
}
