import { useContext } from 'react';
import { api, end, GlobalContext } from '../config/imports';

export function Authorize(required) {
  const { store } = useContext(GlobalContext);
    const { role_id } = store();
    if (role_id <= required) {
      return true;
    }
    return false;

}

async function createLead({ name, email, phone, message }) {
  const data = {
    name,
    email,
    phone,
    message,
  };

  const response = await api.post(end.lead.create, data);
  return response.data;
}

export { createLead };

export async function recover(email, cpf) {
  const response = await api.post(end.auth.recover, {
    email,
    cpf,
  });

  return response;
}

export async function reset(email, cpf, password, otp) {
  const response = await api.post(end.auth.reset, {
    email,
    cpf,
    password,
    otp,
  });

  return response;
}

export async function readCRI(params, consulta = 'registro') {
  const urlparams = api.createURL(params);
  const res = await api.postAPI(end.criBWAPI[consulta], { urlparams });
  return res.data;
}
