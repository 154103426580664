import { useContext, useEffect, useState } from 'react';
import { Table } from 'react-bootstrap';

import {
  Account,
  api,
  end,
  EtapaIcon,
  GlobalContext,
  PKG,
  CONF,
  Support,
} from '../config/imports';

export default function Client() {
  const { checkLogin, roleCode, userCode, token, showSection, store } =
    useContext(GlobalContext);
  const { theme } = useContext(PKG.PackageContext);
  const { curr } = PKG.SCR.Utils;
  const { proc } = end;
  const { post } = api;

  const { pc, codigo } = store();

  const [arrayProcessos, setArrayProcessos] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {}, [showSection]);

  useEffect(() => {
    const get_processos = async () => {
      setLoading(true);
      try {
        const response = await post(proc.read.full, {
          token,
          roleCode: pc,
          userCode: codigo,
        });
        const data = response.data;
        setArrayProcessos(data);
      } catch (error) {
        console.log(error);
      }
      setLoading(false);
    };
    checkLogin();
    get_processos();
  }, [checkLogin, token, roleCode, userCode, post, proc, codigo, pc]);

  if (loading) {
    return (
      <>
        <PKG.LoadingDots conf={CONF} />
      </>
    );
  }

  if (showSection === 'account') return <Account />;
  if (showSection === 'support') return <Support />;

  return (
    <>
      <h4 className='ffSoft fw500 mb-3'>Meus Processos</h4>
      <Table
        className='table table-striped table-hover text-center'
        variant={theme}
      >
        <thead>
          <tr className='tableSimulacoes ffSoft'>
            <th
              scope='col'
              className=''
            >
              Correspondente
            </th>

            <th
              scope='col'
              className=' divHide1000'
            >
              Cidade
            </th>
            <th
              scope='col'
              className=''
            >
              Operação
            </th>
            <th
              scope='col'
              className=''
            >
              Valor
            </th>

            <th
              scope='col'
              className=''
            >
              Etapa Atual
            </th>
            <th
              scope='col'
              className=''
            ></th>
          </tr>
        </thead>
        <tbody>
          {arrayProcessos.length > 0 &&
            arrayProcessos.map(
              ({
                PROCESSO_CODIGO,
                CORRESPONDENTE_NOME,
                CIDADE_IMOVEL,
                OPERACAO,
                VALOR_IMOVEL,
                ETAPA_ATUAL,
              }) => (
                <tr
                  key={PROCESSO_CODIGO}
                  className='tableSimulacoes'
                >
                  <td>{CORRESPONDENTE_NOME || '-'}</td>
                  <td className='divHide1000'>{CIDADE_IMOVEL}</td>
                  <td>{OPERACAO}</td>
                  <td>{curr(VALOR_IMOVEL)}</td>
                  <td>
                    <EtapaIcon
                      etapa={ETAPA_ATUAL}
                      className='me-2'
                    />
                    <span className='px-2'>{ETAPA_ATUAL}</span>
                  </td>
                  <td>
                    <span className='d-flex p-2'>
                      <PKG.IconsUI
                        info={PKG.ui.folder.open}
                        clsnm='svg14 mx-2 cGreen mousePointerHover '
                        tooltip='Abrir processo'
                        link={`/processo/${PROCESSO_CODIGO}`}
                      />
                    </span>
                  </td>
                </tr>
              ),
            )}
        </tbody>
      </Table>
    </>
  );
}
