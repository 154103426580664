import { addYears, differenceInMonths, parse } from 'date-fns';
import { numberFormat } from './helpers';

const evaluateFaixas = (renda, imovel, faixas) => {
  let resultado = {};
  const mcmv_0 = {};
  renda <= 2000 ? (mcmv_0.renda = true) : (mcmv_0.renda = false);
  imovel <= 170000 ? (mcmv_0.imovel = true) : (mcmv_0.imovel = false);
  renda <= 2000 && imovel <= 170000
    ? (mcmv_0.subsidio = true)
    : (mcmv_0.subsidio = false);
  mcmv_0.success = mcmv_0.renda && mcmv_0.imovel ? true : false;
  resultado.mcmv_0 = mcmv_0;

  for (let i = 1; i <= 5; i++) {
    let obj = {};
    let faixa = `mcmv_${i}`;
    if (renda > faixas[`mcmv_0`].renda && renda <= faixas[`mcmv_${i}`].renda) {
      obj.renda = true;
    } else {
      obj.renda = false;
    }
    if (
      imovel > faixas[`mcmv_0`].imovel &&
      imovel < faixas[`mcmv_${i}`].imovel + 0.01
    ) {
      obj.imovel = true;
    } else {
      obj.imovel = false;
    }
    obj.subsidio = false;

    obj.success = obj.renda && obj.imovel ? true : false;
    resultado[faixa] = obj;
  }

  for (let key in faixas) {
    if (!key.includes('mcmv')) {
      let obj = {};
      if (renda > faixas[key].renda) obj.renda = true;
      if (imovel > faixas[key].imovel) obj.imovel = true;
      obj.subsidio = false;
      obj.success = obj.renda && obj.imovel ? true : false;
      resultado[key] = obj;
    }
  }
  return resultado;
};

const taxaPoupanca = (tr_ano, variacoes) => {
  if (parseFloat(variacoes['selic'].value) > 8.5) {
    return parseFloat(tr_ano) + 12 * 0.5;
  }
  return 0.7 * parseFloat(variacoes['selic'].value) + tr_ano;
};

const calcularMeses = (dataNascimento) => {
  const dataNascimentoDate = parse(dataNascimento, 'dd/MM/yyyy', new Date());
  const data70Anos = addYears(dataNascimentoDate, 70);
  const dataAtual = new Date();
  const totalMesesDiferenca = differenceInMonths(data70Anos, dataAtual);
  if (totalMesesDiferenca > 420) return 420;
  return totalMesesDiferenca;
};

const classTr = (result, beneficiado, possuiImovel) => {
  const match =
    result.evaluate &&
    result.evaluate.success &&
    result.compativel &&
    result.compativelPrice
      ? 'bg-table-greener-light'
      : result.evaluate &&
        result.evaluate.success &&
        (result.compativel || result.compativelPrice)
      ? 'bg-table-green-light'
      : '';
  const isMcmv = result.tabela.includes('mcmv');
  if (match && isMcmv && (beneficiado || possuiImovel))
    return 'bg-table-grey-light';
  return match;
};

const tooltip_faixa = (result, faixas) => {
  const faixa = result.tabela;
  const valor_imovel = faixas[faixa].imovel;
  const valor_renda = faixas[faixa].renda;
  return `Renda: ${numberFormat(valor_renda)}
Imóvel: ${numberFormat(valor_imovel)}`;
};

const tooltipParcela = (result, tab, parcelaMax) => {
  const comp = tab === 'SAC' ? result.compativel : result.compativelPrice;
  const parc = tab === 'SAC' ? result.sacInicial : result.price;
  if (comp) return `Renda compatível com a parcela inicial ${tab}`;
  return `Parcela máxima suportada pela renda: ${numberFormat(parcelaMax)}
Renda incompatível com a parcela${
    tab === 'SAC' ? ' inicial ' : ' '
  }${tab}: ${numberFormat(parc)}`;
};

const tooltipIcons = (result, icon, faixas, moneyValues) => {
  const faixa = faixas[result.tabela]['name'];
  const renda = faixas[result.tabela]['renda'];
  const imovel = faixas[result.tabela]['imovel'];
  const evaluate = result.evaluate;
  const rendaText = `Renda ${
    faixa.includes('MCMV') ? 'limite' : 'mínima'
  }: ${numberFormat(renda)}
Renda informada: ${numberFormat(moneyValues.renda)}`;

  const tooltipRenda =
    evaluate && evaluate.renda
      ? `Compatível:
${rendaText}`
      : `Incompatível:
${rendaText}`;

  const imovelText = `Imóvel limite: ${numberFormat(imovel)}
Imóvel escolhido: ${numberFormat(moneyValues.imovel)}`;

  const tooltipImovel =
    evaluate && evaluate.imovel
      ? `Compatível:
${imovelText}`
      : `Incompatível:
${imovelText}`;

  const tooltipSubsidio =
    evaluate && evaluate.subsidio ? `Possui subsídio` : `Não possui subsídio`;

  if (icon === 'renda') return tooltipRenda;
  if (icon === 'imovel') return tooltipImovel;
  if (icon === 'subsidio') return tooltipSubsidio;
};

export {
  calcularMeses,
  classTr,
  evaluateFaixas,
  taxaPoupanca,
  tooltipIcons,
  tooltipParcela,
  tooltip_faixa,
};
