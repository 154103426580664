import React, { useEffect } from 'react';
import { Route, Routes } from 'react-router-dom';

import './packages/wookie/assets/css';
import './assets/css/_root_.css';
import './assets/css/verified.css';

import {
  SEO,
  CONF,
  GlobalProvider,
  Login,
  Logout,
  Processo,
  ProcessoReport,
  Public,
  Recover,
  Register,
  Simulador,
  User,
} from './config/imports';

import { AuthProvider, PackageProvider, Wrapper } from './packages/wookie';

export default function App() {
  useEffect(() => {
    SEO.defineMeta();
    SEO.defineSchema();

    document.title = `${CONF.tenant} ${CONF.headline}`;

    const mediaQuery = window.matchMedia('(prefers-color-scheme: dark)').matches
      ? 'dark'
      : 'light';
    const savedTheme = localStorage.getItem('theme') || mediaQuery;
    if (savedTheme) {
      document.documentElement.className = savedTheme;
    }

  }, []);

  return (
    <AuthProvider>
      <PackageProvider>
        <GlobalProvider>
          <Routes>
            <Route
              path='/login'
              element={<Login />}
            />
            <Route
              path='/logout'
              element={<Logout />}
            />
            <Route
              path='/processo/:codigo'
              element={<Processo />}
            />
            <Route
              path='/report/processo/:codigo'
              element={<ProcessoReport />}
            />

            <Route
              path='/recover'
              element={<Recover />}
            />
            <Route
              path='/register'
              element={<Register />}
            />
            <Route
              path='/thanks'
              element={
                <Wrapper
                  text='Recebemos suas informações e entraremos em contato em breve'
                  clsnmText='fw500 fs16'
                  textHeader='Obrigado!'
                  conf={CONF}
                />
              }
            />
            <Route
              path='/simulador'
              element={<Simulador />}
            />
            <Route
              path='/simule'
              element={<Simulador full={false} />}
            />
            <Route
              path='/user/:codigo'
              element={<User />}
            />

            <Route
              exact
              path='/'
              element={<Public />}
            />
            <Route
              exact
              path='/404'
              name='Page 404'
              element={
                <Wrapper
                  text='Ooops! Página não encontrada'
                  clsnmText='fw-500 fs-16'
                  textHeader='Erro 404'
                  conf={CONF}
                />
              }
            />
            <Route
              exact
              path='/500'
              name='Page 500'
              element={
                <Wrapper
                  text='Acesso não permitido'
                  clsnmText='fw-500 fs-16'
                  textHeader='Erro 500'
                  conf={CONF}
                />
              }
            />
            <Route
              path='*'
              element={
                <Wrapper
                  text='Ooops! Página não encontrada'
                  clsnmText='fw-500 fs-16'
                  textHeader='Erro 404'
                  conf={CONF}
                />
              }
            />
          </Routes>
        </GlobalProvider>
      </PackageProvider>
    </AuthProvider>
  );
}
