import { useContext } from 'react';
import { PackageContext } from '..';

export default function Wrapper({
  conf = {},
  text = '',
  clsnmText = '',
  textHeader = '',
  clsnmTextHeader = '',
  
}) {
  const { theme } = useContext(PackageContext);

  return (
    <div className='wrapper text-center'>
      <a
        href='/'
        rel='noreferrer'
        target='_blank'
        className='logoHeader'
      >
        <img
          src={conf.images[theme].logotext}
          className={`homeLogo mb-1`}
          alt={conf.tenant}
        />
      </a>
      <div
        className={`fs18 fw600 ffSoft ${clsnmTextHeader}`}
      >
        {textHeader}
      </div>
      <div className={`mt-1 fs16 ${clsnmText}`}>{text}</div>
    </div>
  );
}
