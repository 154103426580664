import React, { useContext, useState } from 'react';
import Form from 'react-bootstrap/Form';
import { IMaskInput } from 'react-imask';
import '../../assets/css/app/financiamentos.css';
import {
  CONF,
  SCR,
  PKG,
  RendaGraph,
  GlobalContext,
} from '../../config/imports';

export default function Financiamentos({
  vr_renda = 0,
  vr_imovel = 0,
  vr_entrada = 0,
  dt_nascimento = '',
  fgts_3anos = false,
  beneficiado_mcmv = false,
  possui_imovel_reg = false,
  ismodal = false,
  full = false,
  datahtml2canvasignore = true,
}) {
  const {
    calcularMeses,
    classTr,
    evaluateFaixas,
    taxaPoupanca,
    tooltipIcons,
    tooltipParcela,
    tooltip_faixa,
  } = SCR.Financ;
  const { mascaraMoeda, numberFormat, numberReFormat } = SCR.Helpers;

  const { theme } = useContext(PKG.PackageContext);
  const { a } = useContext(GlobalContext);

  const fx = a.faixas;

  function editFaixas(arr) {
    return arr.reduce((result, obj) => {
      const key = obj.NOME.replace(' ', '_').toLowerCase();
      result[key] = {
        id: `${obj.TABELA.toLowerCase()}_${obj.NOME.replace(
          ' ',
          '_',
        ).toLowerCase()}`,
        name: obj.NOME,
        renda: obj.VAR_RENDA,
        imovel: obj.VAR_IMOVEL,
        taxa: obj.VALUE,
        variacao: obj.T || null,
      };
      return result;
    }, {});
  }

  function editVariacoes(arr) {
    return arr.reduce((result, obj) => {
      const key = obj.NOME.replace(' ', '_')
        .replace('á', 'a')
        .replace('ç', 'c')
        .replace('.', '')
        .replace(' ', '_')
        .toLowerCase();
      result[key] = {
        id: `variacoes_${key}`,
        value: obj.VALUE,
        name: obj.NOME,
      };
      return result;
    }, {});
  }

  const variacoesCEF = editVariacoes(a.variacoes);
  const faixas = editFaixas(fx);

  const [useVariacoes, setUseVariacoes] = useState(variacoesCEF);
  const [linhas, setLinhas] = useState(faixas);
  const [moneyCurrency, setMoneyCurrency] = useState({
    imovel: mascaraMoeda(parseFloat(vr_imovel).toFixed(2)),
    entrada: mascaraMoeda(parseFloat(vr_entrada).toFixed(2)),
    renda: mascaraMoeda(parseFloat(vr_renda).toFixed(2)),
  });
  const [moneyValues, setMoneyValues] = useState({
    imovel: vr_imovel,
    entrada: vr_entrada,
    renda: vr_renda,
  });

  const parcelaMaxInicial = moneyValues.renda * 0.3;

  const [parcelas, setParcelas] = useState(360);
  const [nascimento, setNascimento] = useState(dt_nascimento);
  const [parcelaMax, setParcelaMax] = useState(parcelaMaxInicial);
  const [fgts, setFgts] = useState(fgts_3anos);
  const [beneficiado, setBeneficiado] = useState(beneficiado_mcmv);
  const [possuiImovel, setPossuiImovel] = useState(possui_imovel_reg);

  const [graphMaxRenda, setGraphMaxRenda] = useState(10000);
  const [graphMaxImovel, setGraphMaxImovel] = useState(700000);

  const handleMoney = (e) => {
    const { name } = e.target;
    const valueReformatted = mascaraMoeda(e.target.value);
    const valueNumber = numberReFormat(valueReformatted);
    // setMoneyCurrency({ ...moneyCurrency, [name]: valueReformatted });
    // setMoneyValues({ ...moneyValues, [name]: valueNumber });
    if (name === 'renda') {
      let max = valueNumber * 0.3;
      setParcelaMax(max);
      setMoneyCurrency({ ...moneyCurrency, renda: valueReformatted });
      setMoneyValues({ ...moneyValues, renda: valueNumber });
    } else if (name === 'imovel') {
      let entrada = valueNumber * 0.2;
      setMoneyCurrency({
        ...moneyCurrency,
        entrada: mascaraMoeda(entrada.toFixed(2)),
        imovel: valueReformatted,
      });
      setMoneyValues({ ...moneyValues, entrada, imovel: valueNumber });
    } else {
      setMoneyCurrency({ ...moneyCurrency, [name]: valueReformatted });
      setMoneyValues({ ...moneyValues, [name]: valueNumber });
    }
  };

  const handleRenda = (valor) => {
    const valueReformatted = mascaraMoeda(valor);
    setMoneyCurrency({ ...moneyCurrency, renda: valueReformatted });
    setMoneyValues({ ...moneyValues, renda: valor });
    let max = valor * 0.3;
    setParcelaMax(max);
  };

  const handleImovel = (valor) => {
    const valueReformatted = mascaraMoeda(valor);
    setMoneyCurrency({ ...moneyCurrency, imovel: valueReformatted });
    setMoneyValues({ ...moneyValues, imovel: valor });
    let entrada = valor * 0.2;
    setMoneyCurrency({
      ...moneyCurrency,
      imovel: valueReformatted,
      entrada: mascaraMoeda(entrada.toFixed(2)),
    });
    setMoneyValues({ ...moneyValues, entrada, imovel: valor });
  };

  const handleClick = () => {
    const { imovel, entrada } = moneyValues;
    const financiado = imovel - entrada;
    let results = [];
    let evaluation = evaluateFaixas(
      moneyValues.renda,
      moneyValues.imovel,
      faixas,
    );

    Object.keys(faixas).forEach((key) => {
      let thisTaxa = faixas[key]['taxa'];
      const variacao = faixas[key]['variacao'];
      if (variacao) {
        if (variacao === 'poup') {
          thisTaxa = taxaPoupanca(
            parseFloat(useVariacoes['tr'].value),
            useVariacoes,
          );
        } else {
          thisTaxa = useVariacoes[variacao]['value'] + faixas[key]['taxa'];
        }
      }
      if (
        fgts &&
        (faixas[key]['name'].includes('MCMV') ||
          faixas[key]['name'].includes('SBPE')) &&
        !beneficiado &&
        !possuiImovel
      )
        thisTaxa -= 0.5;
      let resultVariacao = variacao
        ? `${useVariacoes[variacao]['name']}: ${useVariacoes[variacao]['value']} %`
        : '';

      let fin = new SCR.Financiar(
        financiado,
        thisTaxa,
        parcelas,
        useVariacoes.seguro.value,
        useVariacoes.taxa_admin.value,
        'anual',
        useVariacoes.max_parcelas_sac.value,
        useVariacoes.max_parcelas_price.value,
      );

      let result = fin.result();
      const compativel = result.sacInicial <= parcelaMax;
      const compativelPrice = result.price <= parcelaMax;
      const ev = evaluation[key];
      results.push({
        tabela: key,
        variacao: resultVariacao,
        compativel,
        compativelPrice,
        evaluate: ev,
        tx: parseFloat(thisTaxa),
        ...result,
      });
    });

    return (
      <>
        {results.map((result) => {
          return (
            <tr
              key={result.tabela}
              className={classTr(result, beneficiado, possuiImovel)}
              data-toggle='tooltip'
              title={
                classTr(result, beneficiado, possuiImovel) ===
                'bg-table-grey-light'
                  ? `Já foi beneficiado pelo MCMV ou possui imóvel registrado. Não é possível utilizar essa faixa.`
                  : ''
              }
            >
              <td
                className=''
                data-toggle='tooltip'
                title={tooltip_faixa(result, faixas)}
              >
                {faixas[result.tabela]['name']}
              </td>
              <td className=''>
                <span
                  data-toggle='tooltip'
                  title={tooltipIcons(result, 'renda', faixas, moneyValues)}
                >
                  <PKG.IconsUI
                    info={PKG.ui.misc.money}
                    clsnm={`mx-1 svg14 ${
                      result.evaluate && result.evaluate.renda
                        ? 'cGreen'
                        : 'cGray300'
                    }`}
                  />
                </span>
                <span
                  data-toggle='tooltip'
                  title={tooltipIcons(result, 'imovel', faixas, moneyValues)}
                >
                  <PKG.IconsUI
                    info={PKG.ui.misc.house}
                    clsnm={`mx-1 svg14 ${
                      result.evaluate && result.evaluate.imovel
                        ? 'cGreen'
                        : 'cGray300'
                    }`}
                  />
                </span>
                <span
                  data-toggle='tooltip'
                  title={tooltipIcons(result, 'subsidio', faixas, moneyValues)}
                >
                  <PKG.IconsUI
                    info={PKG.ui.misc.star}
                    clsnm={`mx-1 svg14 ${
                      result.evaluate && result.evaluate.subsidio
                        ? 'cGreen'
                        : 'cGray300'
                    }`}
                  />
                </span>
              </td>
              <td
                className={`divHide600 ${ismodal ? 'divHide' : ''}`}
              >{`${parseFloat(faixas[result.tabela].taxa).toFixed(2)} %`}</td>
              <td className={`divHide600 ${ismodal ? 'divHide' : ''}`}>
                {result.variacao}
              </td>
              <td className=''>{`${parseFloat(result.tx).toFixed(4)} %`}</td>
              <td className={`divHide600 ${ismodal ? 'divHide' : ''}`}>
                {result.encargos}
              </td>
              <td
                data-toggle='tooltip'
                title={tooltipParcela(result, 'SAC', parcelaMax)}
              >
                <span
                  className={
                    result.evaluate &&
                    result.evaluate.success &&
                    result.compativel
                      ? 'cGreen'
                      : result.evaluate &&
                        result.evaluate.success &&
                        !result.compativel
                      ? 'cRed'
                      : ''
                  }
                >
                  {result.primeiraParcelaSac}
                </span>
                <br />
                <span className={parcelaMax < result.sacFinal ? 'cRed' : ''}>
                  {result.ultimaParcelaSac}
                </span>
              </td>
              <td
                data-toggle='tooltip'
                title={tooltipParcela(result, 'PRICE', parcelaMax)}
                className={`${
                  result.evaluate &&
                  result.evaluate.success &&
                  result.compativelPrice
                    ? 'cGreen'
                    : result.evaluate &&
                      result.evaluate.success &&
                      !result.compativelPrice
                    ? 'cRed'
                    : ''
                } ${
                  classTr(result, beneficiado, possuiImovel) ===
                    'bg-table-green-light' &&
                  !result.compativel &&
                  result.compativelPrice
                    ? 'single-td-green-light'
                    : ''
                }`}
              >
                {result.prestacaoPrice}
              </td>

              <td className=''>
                <span className='textContrast'>{result.totalJurosSac}</span>
                <br />
                <span>{result.totalPagoSac}</span>
              </td>

              <td className=''>
                <span className='textContrast'>{result.totalJurosPrice}</span>
                <br />
                <span>{result.totalPagoPrice}</span>
              </td>
            </tr>
          );
        })}
      </>
    );
  };

  return (
    <div
      className='setWidth my-5'
      data-html2canvas-ignore={datahtml2canvasignore}
    >
      <header
        data-html2canvas-ignore={true}
        className={`fs10 pt-1 pb-2 divHide ${
          ismodal ? 'divShow' : ''
        } text-center`}
      >
        <img
          src={CONF.images[theme].logotext}
          className='logoHeader'
          alt='logo'
        />
        <h5 className='mt-5'>Calculadora de Financiamento Imobiliário</h5>
        <p className={`fs10 pt-1 pb-2`}>
          Esse cálculo não inclui as taxas e seguros inclusos pelos bancos.
          <br />
          Para um cálculo mais detalhado, preencha o{' '}
          <a
            href='https://www.hamanncorp.com/simulador'
            target='_blank'
            rel='noreferrer'
            aria-label='formulário de simulação'
          >
            formulário de simulação
          </a>
        </p>
      </header>
      <div className='body'>
        <div className='d-flex flex-row justify-content-start align-items-center my-2'>
          <span className='fs16 fw500 ffSoft textContrastColor'>
            Simulador de Financiamento
          </span>
          <PKG.IconsUI
            info={PKG.ui.expand.more}
            clsnm='svg20 cLink hovGreen mousePointerHover ms-2'
            click={() => {
              const form_el = document.getElementById('formSimulador');
              form_el.classList.toggle('divHide');
            }}
          />
        </div>

        <form
          action=''
          id='formSimulador'
          data-html2canvas-ignore={true}
          className={`fs12 pt-1 pb-2 faLeft p-3 rounded ${
            ismodal ? 'divHide' : ''
          }`}
        >
          <div className='bet gocol '>
            <div className='d-flex flex-row justify-content-around gocol1000 mb-2'>
              <div className='gocol border rounded border-success p-3 fs12 mb-2'>
                <h5 className='my-1'>Dados do Financiamento</h5>

                <Form.Group className='gorow gocol450 align-items-center justify-content-start pb-1'>
                  <Form.Label
                    className='fw500 ffSoft wp150 m-0'
                    htmlFor='imovel'
                  >
                    Valor do Imóvel
                  </Form.Label>

                  <Form.Control
                    type='text'
                    size='sm'
                    className='form-control wp200'
                    name='imovel'
                    id='imovel'
                    value={moneyCurrency.imovel}
                    onChange={(e) => handleMoney(e)}
                  />
                </Form.Group>

                <Form.Group className='gorow gocol450 align-items-center justify-content-start pb-1'>
                  <Form.Label
                    className='fw500 ffSoft wp150 m-0'
                    htmlFor='entrada'
                  >
                    Valor da Entrada
                  </Form.Label>
                  <Form.Control
                    type='text'
                    size='sm'
                    className='form-control wp200'
                    name='entrada'
                    id='entrada'
                    value={moneyCurrency.entrada}
                    onChange={(e) => handleMoney(e)}
                  />
                </Form.Group>
                <span
                  id='info-entrada'
                  className='fs10 mb-1'
                  onClick={() => {
                    const value_entrada = Number(
                      (moneyValues.imovel / 5).toFixed(2),
                    );
                    setMoneyCurrency({
                      ...moneyCurrency,
                      entrada: numberFormat(value_entrada),
                    });
                    setMoneyValues({
                      ...moneyValues,
                      entrada: value_entrada,
                    });
                  }}
                >
                  {moneyValues.imovel > 0 ? (
                    <>
                      <span className='cGray300'>
                        A entrada mínima deve ser de 20% do valor do imóvel:{' '}
                        {numberFormat(moneyValues.imovel / 5)}.
                      </span>{' '}
                      <span className='hovGreen mousePointerHover'>
                        Clique aqui para definir esse valor.
                      </span>
                    </>
                  ) : (
                    ''
                  )}
                </span>

                <Form.Group className='gorow gocol450 align-items-center justify-content-start pb-1'>
                  <Form.Label
                    className='fw500 ffSoft wp150 m-0'
                    htmlFor='renda'
                  >
                    Renda
                  </Form.Label>
                  <Form.Control
                    type='text'
                    size='sm'
                    className='form-control wp200'
                    name='renda'
                    id='renda'
                    value={moneyCurrency.renda}
                    onChange={(e) => handleMoney(e)}
                  />
                </Form.Group>
                <span
                  className='fs10 mb-1'
                  id='info-renda'
                >
                  {moneyValues.renda > 0 ? (
                    <span className='cGray300'>
                      A parcela não poderá exceder 30% da renda. A parcela
                      máxima será de {numberFormat(moneyValues.renda * 0.3)}
                    </span>
                  ) : (
                    ''
                  )}
                </span>

                <Form.Group className='gorow gocol450 align-items-center justify-content-start pb-1'>
                  <Form.Label
                    className='fw500 ffSoft wp150 m-0'
                    htmlFor='nascimento'
                  >
                    Nascimento
                  </Form.Label>
                  <Form.Control
                    type='text'
                    as={IMaskInput}
                    mask='00/00/0000'
                    id='nascimento'
                    name='nascimento'
                    size='sm'
                    className='form-control wp200'
                    required
                    value={nascimento}
                    onChange={(e) => {
                      setNascimento(e.target.value);
                      setParcelas(calcularMeses(e.target.value));
                    }}
                  />
                </Form.Group>

                <Form.Group className='gorow gocol450 align-items-center justify-content-start pb-1'>
                  <Form.Label
                    className='fw500 ffSoft wp150 m-0'
                    htmlFor='parcelas'
                  >
                    Parcela Máxima
                  </Form.Label>
                  <Form.Control
                    type='number'
                    name='parcelas'
                    size='sm'
                    className='form-control wp200'
                    id='parcelas'
                    value={parcelas}
                    min='12'
                    max='420'
                    maxLength={3}
                    onChange={(e) => setParcelas(e.target.value)}
                  />
                </Form.Group>

                <Form.Check // prettier-ignore
                  type='checkbox'
                  id='check_3anos_fgts'
                  label='Possui 3 anos de FGTS'
                  checked={fgts}
                  onChange={(e) => setFgts(e.target.checked)}
                />
                <Form.Check // prettier-ignore
                  type='checkbox'
                  id='beneficiado'
                  label='Já foi beneficiado pelo MCMV'
                  checked={beneficiado}
                  onChange={(e) => setBeneficiado(e.target.checked)}
                />
                <Form.Check // prettier-ignore
                  type='checkbox'
                  id='possui_imovel'
                  label='Possui imóvel registrado'
                  checked={possuiImovel}
                  onChange={(e) => setPossuiImovel(e.target.checked)}
                />
              </div>

              <div className='gocol p-3 fs12 border rounded border-secondary mb-2'>
                <div className='d-flex flex-row align-items-center justify-content-between'>
                  <h5 className='my-1'>Dados Variáveis</h5>
                  <PKG.IconsUI
                    info={PKG.ui.expand.more}
                    clsnm='svg20 cLink  hovGreen mousePointerHover'
                    click={() => {
                      const div_el = document.getElementById(
                        'div_dados_variaveis',
                      );
                      div_el.classList.toggle('divHide');
                    }}
                  />
                </div>
                <div
                  className='d-flex flex-wrap ms-3 divHide'
                  id='div_dados_variaveis'
                >
                  {Object.keys(useVariacoes).map((key) => {
                    return (
                      <Form.Group
                        key={`div_${useVariacoes[key].id}`}
                        className='gorow gocol450 align-items-center justify-content-start pb-1 mx-2'
                      >
                        <Form.Label
                          className='fw500 ffSoft wp150 wp100 m-0'
                          htmlFor={`input_${useVariacoes[key].id}`}
                        >
                          {useVariacoes[key].name}
                        </Form.Label>
                        <Form.Control
                          type='number'
                          step='0.01'
                          className='form-control wp100'
                          size='sm'
                          name={`input_${useVariacoes[key].id}`}
                          id={`input_${useVariacoes[key].id}`}
                          value={useVariacoes[key].value}
                          onChange={(e) => {
                            setUseVariacoes({
                              ...useVariacoes,
                              [key]: {
                                ...useVariacoes[key],
                                value: parseFloat(e.target.value),
                              },
                            });
                          }}
                        />
                      </Form.Group>
                    );
                  })}
                </div>
              </div>
            </div>
            <div className='p-3 border rounded border-secondary'>
              <div className='d-flex flex-row align-items-center justify-content-start'>
                <h5 className='m-1'>Taxas Anuais de Juros %</h5>
                <PKG.IconsUI
                  info={PKG.ui.expand.more}
                  clsnm='svg20 cLink  hovGreen mousePointerHover ms-2'
                  click={() => {
                    const div_el = document.getElementById('div_dados_taxas');
                    div_el.classList.toggle('divHide');
                  }}
                />
              </div>
              <div
                className='d-flex flex-wrap p-3 gorow gocol900 fs12 divHide'
                id='div_dados_taxas'
              >
                {Object.keys(linhas).map((key) => {
                  return (
                    <Form.Group
                      className='gorow gocol450 align-items-center justify-content-start pb-1 mx-2'
                      key={key}
                    >
                      <Form.Label
                        className='fw500 ffSoft wp150 m-0'
                        htmlFor={key}
                      >
                        {linhas[key].name}
                      </Form.Label>
                      <Form.Control
                        type='number'
                        step={0.1}
                        className='form-control wp200'
                        size='sm'
                        id={key}
                        name='taxa'
                        value={linhas[key].taxa}
                        onChange={(e) => {
                          setLinhas({
                            ...linhas,
                            [key]: {
                              ...linhas[key],
                              taxa: parseFloat(e.target.value),
                            },
                          });
                        }}
                      />
                    </Form.Group>
                  );
                })}
                <div className='gorow gocol450 justify-content-start'></div>
              </div>
            </div>

            <span
              className='par-info'
              id='info-taxa'
            ></span>
          </div>
        </form>
      </div>
      <div className={full ? 'd-flex flex-row gocol450' : 'gocol'}>
        <div className={full ? 'mx-2' : ''}>
          <div className='mt-3 d-flex flex-row align-items-center justify-content-start'>
            <span className='fs11 fw400'>Análise de Possibilidades</span>
            <PKG.IconsUI
              info={PKG.ui.expand.more}
              clsnm='svg20 cLink hovGreen mousePointerHover'
              click={() => {
                const div_el = document.getElementById(
                  'div_financiamentos_result',
                );
                div_el.classList.toggle('divHide');
              }}
            />
          </div>
          <div id='div_financiamentos_result'>
            <table
              id='resultado'
              data-bs-theme={theme}
              className={`table table-striped table-hover table-sm `}
            >
              <thead>
                <tr>
                  <td className=''>Tabela</td>
                  <td className=''></td>
                  <td className={`divHide600 ${ismodal ? 'divHide' : ''}`}>
                    Taxa
                  </td>
                  <td className={`divHide600 ${ismodal ? 'divHide' : ''}`}>
                    Variação
                  </td>
                  <td className=''>Taxa Calc</td>
                  <td className={`divHide600 ${ismodal ? 'divHide' : ''}`}>
                    Encargos
                  </td>
                  <td className=''>Parcela SAC</td>
                  <td className=''>Parcela Price</td>

                  <td className=''>Total Sac</td>

                  <td className=''>Total Price</td>
                </tr>
              </thead>
              <tbody className='fs10'>{handleClick()}</tbody>
            </table>
          </div>
        </div>

        <div className={full ? 'mx-2' : ''}>
          <div className='fs09 d-flex flex-row justify-content-between'>
            <div className='gorow gocol450 justify-content-start'>
              <span
                className='fw600 wp150 fs08 wp200'
                htmlFor='maxValorImovel'
              >
                Valor Máximo do Imóvel
              </span>
              <Form.Control
                type='number'
                size='sm'
                className='form-control wp200 wp150'
                name='maxValorImovel'
                id='maxValorImovel'
                value={graphMaxImovel}
                onChange={(e) => setGraphMaxImovel(e.target.value)}
              />
            </div>
            <div className='gorow gocol450 justify-content-start'>
              <span
                className='fw600 wp150 fs08 wp200'
                htmlFor='maxValorRenda'
              >
                Valor Máximo da Renda
              </span>
              <Form.Control
                type='number'
                size='sm'
                className='form-control wp200 wp150'
                name='maxValorRenda'
                id='maxValorRenda'
                value={graphMaxRenda}
                onChange={(e) => setGraphMaxRenda(e.target.value)}
              />
            </div>
          </div>

          <RendaGraph
            defineRenda={(v) => handleRenda(v)}
            defineImovel={(v) => handleImovel(v)}
            maxImovel={graphMaxImovel}
            maxRenda={graphMaxRenda}
          />
        </div>
      </div>
    </div>
  );
}
