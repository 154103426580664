import React, { useEffect, useState, useContext } from 'react';

import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import { PKG } from '../../config/imports';

export default function ConcluidoModal({
  show,
  setShow,
  handleSave,
  handleClose,
  handleDate,
  id_concluido,
  ind,
  data_inicial,
  etapaNome,
}) {
  const [dataConcluido, setDataConcluido] = useState(
    new Date().toISOString().split('T')[0],
  );
  const [dataStart, setDataStart] = useState(
    new Date(data_inicial).toISOString().split('T')[0],
  );

  const [sendMail, setSendMail] = useState(true);
  const { theme } = useContext(PKG.PackageContext);

  useEffect(() => {
    setDataStart(new Date(data_inicial).toISOString().split('T')[0]);
    setDataConcluido(new Date().toISOString().split('T')[0]);
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <Modal
        show={show}
        size='lg'
        aria-labelledby='contained-modal-title-vcenter'
        centered
        onHide={setShow}
        backdrop='static'
        keyboard={false}
        className='cstModal'
        data-bs-theme={theme}
      >
        <Modal.Header>
          <Modal.Title className='ffSoft'>Concluir {etapaNome}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <Form.Label className='ffSoft f08'>Início</Form.Label>
            <Form.Control
              required
              type='date'
              id={`id_modal_started_${id_concluido}`}
              value={dataStart}
              className='form-control input-cur w-100 mb-4'
              onChange={(e) => {
                setDataStart(e.target.value);
                handleDate();
              }}
            />

            <Form.Label className='ffSoft f08'>Encerramento</Form.Label>
            <Form.Control
              required
              type='date'
              id={`id_modal_done_${id_concluido}`}
              value={dataConcluido}
              className='form-control input-cur w-100 mb-1'
              onChange={(e) => {
                setDataConcluido(e.target.value);
                handleDate();
              }}
            />

            <Form.Check
              className='ffSoft f08 mt-3'
              onChange={(e) => {
                setSendMail(e.target.checked);
              }}
              checked={sendMail}
              type='checkbox'
              id={`id_modal_done_send_mail_${id_concluido}`}
              label='Notificar o cliente por e-mail'
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            size='sm'
            variant='secondary'
            onClick={() => {
              handleClose(ind);
              setShow();
            }}
          >
            Cancelar
          </Button>
          <Button
            size='sm'
            variant='primary'
            onClick={() => {
              setDataStart(
                new Date(
                  document.getElementById(
                    `id_modal_started_${id_concluido}`,
                  ).value,
                )
                  .toISOString()
                  .split('T')[0],
              );
              setDataConcluido(
                new Date(
                  document.getElementById(
                    `id_modal_done_${id_concluido}`,
                  ).value,
                )
                  .toISOString()
                  .split('T')[0],
              );
              handleDate();
              handleSave(ind);
              setShow();
            }}
          >
            Concluir Etapa
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
