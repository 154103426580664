import html2pdf from 'html2pdf.js';
import { useCallback, useContext, useEffect, useState } from 'react';
import Badge from 'react-bootstrap/Badge';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Spinner from 'react-bootstrap/Spinner';
import { RotatingSquare } from 'react-loader-spinner';

import {
  CONF,
  PKG,
  api,
  BlobViewer,
  DragAndDrop,
  end,
  Financiamentos,
  GlobalContext,
  ModalLine,
} from '../../config/imports';

export default function SimulacaoModal({
  obj,
  on,
  changeActive,
  load,
  del,
  full,
}) {
  const { addSpaceComma, curr, dataBr, isTrue } = PKG.SCR.Utils;
  const { sim, sim_docs } = end;
  const { fileIcon } = PKG;

  const { store, a } = useContext(GlobalContext);
  const { theme, changeTheme } = useContext(PKG.PackageContext);
  const savedTheme = theme;
  const [anotacoes, setAnotacoes] = useState(obj.SIM_ANOTACOES);
  const [loading, setLoading] = useState();
  const [uploading, setUploading] = useState(false);
  const [files, setFiles] = useState([]);
  const [objFiles, setObjFiles] = useState([]);
  const [sendingEmail, setSendingEmail] = useState(false);
  const [email, setEmail] = useState('');
  const [infoEmail, setInfoEmail] = useState('');

  const handleSaveAsPDF = () => {
    const opt = {
      margin: 1,
      filename: `Simulação_${obj.SIM_NOME}_${
        new Date(obj.SIM_CREATED).toISOString().split('T')[0]
      }_${obj.SIM_ID}.pdf`,
      autoPaging: 'text',
      enableLinks: false,
      html2canvas: {
        allowTaint: true,
        letterRendering: true,
        logging: false,
        scale: 2,
      },
      jsPDF: {
        unit: 'cm',
        format: 'a4',
        orientation: 'portrait',
        compress: false,
        autoPaging: 'text',
        logging: false,
        putOnlyUsedFonts: true,
      },
    };
    return opt;
  };

  const downloadPDF = async () => {
    document.documentElement.className = 'light';
    const element = document.getElementById(`mdsim_${obj.SIM_CODIGO}`);
    const opt = handleSaveAsPDF();
    html2pdf().set(opt).from(element).save();
    const blob = await html2pdf().set(opt).from(element).outputPdf('blob');
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.target = '_blank';
    link.click();
    changeTheme(savedTheme);
  };

  const sendPdfToMail = async () => {
    setSendingEmail(true);
    setInfoEmail('Enviando email...');
    const emailTo = document.getElementById('mailto').value;
    const element = document.getElementById(`mdsim_${obj.SIM_CODIGO}`);
    try {
      const opt = handleSaveAsPDF();
      const blob = await html2pdf().set(opt).from(element).outputPdf('blob');

      const formData = new FormData();
      formData.append('pdf', blob, opt.filename);
      formData.append('email', document.getElementById('mailto').value);
      formData.append('subject', `Simulação ${obj.TIPO} - ${obj.SIM_NOME}`);
      formData.append('filename', opt.filename);

      await api.post(sim.mail, formData, true);
    } catch (error) {
      console.error(error);
    } finally {
      setEmail('');
      setInfoEmail(`E-mail enviado com sucesso para ${emailTo}!`);
      setSendingEmail(false);
    }
  };

  const handleDelete = async (simulacao_codigo, file_codigo) => {
    setLoading(true);
    try {
      await api.post(sim_docs.delete, {
        simulacao_codigo,
        codigo: file_codigo,
      });
      fetchFiles();
    } catch (error) {
      console.error(error);
    }
    setLoading(false);
  };

  const fetchFiles = useCallback(async () => {
    const response = await api.post(sim_docs.read.all, {
      simulacao_codigo: obj.SIM_CODIGO,
    });
    const data = response.data;
    setObjFiles(data);
  }, [obj.SIM_CODIGO, sim_docs]);

  useEffect(() => {
    try {
      fetchFiles();
    } catch (error) {
      console.error(error);
    }
    // eslint-disable-next-line
  }, []);

  const uploadFilesToServer = async () => {
    setUploading(true);

    try {
      const uploadPromises = [];
      const countFiles = files.length;
      let codigo = obj.SIM_CODIGO;
      const { token } = store();

      for (let i = 0; i < countFiles; i += 1) {
        const formData = new FormData();
        formData.append('token', token);
        formData.append('simulacao_codigo', codigo);
        formData.append('title', files[i].description);
        formData.append('files', files[i].file);
        formData.append('files_name', files[i].file.name);
        formData.append('files_type', files[i].file.type);

        const uploadPromise = api.postFiles(sim_docs.create, formData);
        uploadPromises.push(uploadPromise);
      }

      await Promise.all(uploadPromises);
    } catch (error) {
      console.error('Erro ao fazer upload:', error);
    } finally {
      setUploading(false);
    }
    fetchFiles();
    setFiles([]);
  };

  const editAnotacoes = () => {
    const p_anot = document.getElementById(`p_anotacoes_${obj.SIM_ID}`);
    const d_anot = document.getElementById(`div_anotacoes_${obj.SIM_ID}`);
    const t_anot = document.getElementById(`txt_anotacoes_${obj.SIM_ID}`);

    if (obj.SIM_ANOTACOES == null) obj.SIM_ANOTACOES = '';

    if (anotacoes == null || anotacoes === '') {
      setAnotacoes(obj.SIM_ANOTACOES);
    }
    t_anot.innerText = anotacoes;

    p_anot.classList.add('divHide');
    d_anot.classList.remove('divHide');
  };

  const cancel_anot = () => {
    const p_anot = document.getElementById(`p_anotacoes_${obj.SIM_ID}`);
    const d_anot = document.getElementById(`div_anotacoes_${obj.SIM_ID}`);
    p_anot.classList.remove('divHide');
    d_anot.classList.add('divHide');
  };

  const save_anot = async (event) => {
    event.preventDefault();

    const p_anot = document.getElementById(`p_anotacoes_${obj.SIM_ID}`);
    const d_anot = document.getElementById(`div_anotacoes_${obj.SIM_ID}`);
    const t_anot = document.getElementById(`txt_anotacoes_${obj.SIM_ID}`);

    let codigo = obj.SIM_CODIGO;
    let anotacao = t_anot.value;
    setAnotacoes(anotacao);

    try {
      setLoading(true);
      await api.post(sim.update.anotacoes, {
        codigo,
        anotacao,
      });
    } catch (error) {
      console.error(error);
    }
    setLoading(false);

    obj.SIM_ANOTACOES = anotacao;

    p_anot.innerText = anotacao;
    p_anot.classList.remove('divHide');
    d_anot.classList.add('divHide');
  };

  if (loading) return <PKG.LoadingDots conf={CONF} />;

  return (
    <div id={`mdsim_${obj.SIM_CODIGO}`}>
      <div className='d-flex flex-row justify-content-between fs08 textContrastLow mb-1'>
        <span>{`Simulação ${obj.tipo}`}</span>
        <div>
          <span>
            {load ? (
              <Spinner
                animation='border'
                variant='success'
                size='sm'
              />
            ) : on ? (
              <Badge
                className='fw200 ms-1 mousePointerHover'
                bg='success'
                onClick={changeActive}
              >
                ON
              </Badge>
            ) : (
              <Badge
                className='fw200 ms-1 mousePointerHover textContrast'
                bg={theme}
                onClick={changeActive}
              >
                OFF
              </Badge>
            )}
          </span>
          <span className='fw500'>
            {obj.perfil.compor ? (
              <Badge
                className='fw200 ms-1'
                bg='warning'
              >
                Composição de renda
              </Badge>
            ) : (
              <Badge
                className='fw200 ms-1'
                bg='info'
              >
                Individual
              </Badge>
            )}
          </span>
          <span>
            {obj.PROCESSO_CODIGO ? (
              obj.PROCESSO_ACTIVE ? (
                <Badge
                  className='fw200 mx-1'
                  bg='success'
                >
                  Em Processo
                </Badge>
              ) : (
                <Badge
                  className='fw200 mx-1'
                  bg='secondary'
                >
                  Processo Inativo
                </Badge>
              )
            ) : (
              <Badge
                className='fw200 mx-1'
                bg='primary'
              >
                Aguardando Criar Processo
              </Badge>
            )}
            <Badge
              className='fw200 mx-1'
              bg={theme}
              data-html2canvas-ignore={true}
              onClick={downloadPDF}
            >
              <PKG.IconsUI
                info={PKG.ui.action.download}
                clsnm='svg12 tran05 growSvg20 mousePointerHover'
                tooltip='Baixar PDF'
              />
            </Badge>
            <Badge
              className='fw200 mx-1'
              bg={theme}
              data-html2canvas-ignore={true}
              onClick={del}
            >
              <PKG.IconsUI
                info={PKG.ui.action.delete.trash}
                clsnm='svg12 cRed tran05 growSvg20 mousePointerHover'
                tooltip='Deletar'
              />
            </Badge>
          </span>
        </div>
      </div>
      <>
        <div className='d-flex flex-row justify-content-between'>
          <span className='d-flex flex-row justify-content-start'>
            <a href={`/cliente/${obj.SIM_USER_CODIGO}`}>
              <span className='fs16 mx-1'>
                <PKG.IconsUI
                  info={PKG.ui.user.reg}
                  clsnm=''
                />
              </span>
            </a>
            <span className='d-flex flex-column mb-1'>
              <a href={`/cliente/${obj.SIM_USER_CODIGO}`}>
                <span className=''>{obj.USER_NOME}</span>
              </a>
              <span className='fs09'>
                <a href={`mailto:${obj.SIM_EMAIL}`}>{obj.SIM_EMAIL}</a>
              </span>
            </span>
          </span>
          <p>
            <a
              href={obj.perfil.whatsapp}
              target='_blank'
              rel='noreferrer'
            >
              <span className='fs16 mx-1'>
                <PKG.IconsUI
                  info={PKG.ui.social.whatsapp}
                  clsnm='cGreen'
                />
              </span>

              {obj.SIM_WHATSAPP}
            </a>
          </p>
          <p className='d-flex flex-column justify-content-end f08 fw300'>
            {obj.perfil.data_criado}
            <span className=''>
              <span className='me-1'>
                <PKG.IconsUI
                  info={PKG.ui.misc.clock}
                  clsnm=''
                />
              </span>
              {obj.perfil.hora_criado}
            </span>
          </p>
        </div>
        <div className='d-flex flex-column '>
          <div className='fs10 fw400 p-2'>
            <p data-html2canvas-ignore={true}>Detalhe</p>

            <div className='gocol justify-content-around  gocol1000 go100W1000'>
              <div className='col-5 gocol1000 go100W1000'>
                <p className='my-1 fw300 fs10 textContrastColor'>Imóvel</p>
                <ModalLine
                  title='Valor'
                  first={curr(obj.SIM_IMOVEL_VALOR)}
                />
                <ModalLine
                  title='Cidade'
                  first={obj.SIM_IMOVEL_CIDADE}
                />
                <ModalLine
                  title='Operação'
                  first={obj.SIM_IMOVEL_DESCRICAO}
                />
              </div>

              <div className='col-7 gocol1000 go100W1000'>
                <p className='mt-3 mb-1 fw300 fs10 textContrastColor'>Perfil</p>
                <ModalLine
                  title='Nome'
                  first={obj.USER_NOME}
                  second={obj.SIM_OUTRO_NOME}
                />
                {obj.SIM_ESTADO_CIVIL ? (
                  <ModalLine
                    title='Est. Civil'
                    first={obj.SIM_ESTADO_CIVIL}
                    second=''
                  />
                ) : (
                  ''
                )}
                <ModalLine
                  title='Nascimento'
                  first={dataBr(obj.SIM_NASCIMENTO)}
                  second={
                    obj.SIM_COMPLETA ? dataBr(obj.SIM_OUTRO_NASCIMENTO) : ''
                  }
                  double={!obj.SIM_COMPLETA}
                />
                {obj.SIM_CPF ? (
                  <ModalLine
                    title='CPF'
                    first={obj.perfil.cliente.cpf}
                    second={obj.SIM_COMPLETA ? obj.perfil.outro.cpf : ''}
                    double={!obj.SIM_COMPLETA}
                  />
                ) : (
                  ''
                )}
                {obj.SIM_COMPOR ? (
                  <ModalLine
                    title='Relacionamento'
                    first=''
                    second={obj.perfil.relacionamento}
                  />
                ) : (
                  ''
                )}
              </div>
            </div>

            <p className='mt-3 mb-1 fw300 fs10 textContrastColor'>Financeiro</p>

            <ModalLine
              title='Renda'
              first={curr(obj.perfil.cliente.renda)}
              second={obj.SIM_COMPLETA ? curr(obj.perfil.outro.renda) : ''}
              double={!obj.SIM_COMPLETA}
            />
            <ModalLine
              title='Comprovação'
              first={
                obj.perfil.cliente.comprovacao &&
                addSpaceComma(obj.perfil.cliente.comprovacao)
              }
              second={
                obj.SIM_COMPLETA
                  ? obj.perfil.outro.comprovacao &&
                    addSpaceComma(obj.perfil.outro.comprovacao)
                  : ''
              }
              double={!obj.SIM_COMPLETA}
            />
            <ModalLine
              title='Saldo de FGTS'
              first={curr(obj.perfil.cliente.fgts)}
              second={obj.SIM_COMPLETA ? curr(obj.perfil.outro.fgts) : ''}
              double={!obj.SIM_COMPLETA}
            />
            <ModalLine
              title='Outros Recursos'
              first={curr(obj.perfil.cliente.recursos)}
              second={obj.SIM_COMPLETA ? curr(obj.perfil.outro.recursos) : ''}
              double={!obj.SIM_COMPLETA}
            />
          </div>

          {obj.proc.fin.informacoes ? (
            <div className='fs10 fw400 p-2'>
              <p className='mt-3 mb-1 fw300 fs10 textContrastColor'>
                Outras informaçôes
              </p>
              <p>{obj.proc.fin.informacoes}</p>
            </div>
          ) : (
            ''
          )}

          <div className='p-2 mt-2 bgMenu'>
            <p className='fs10 fw400'>Resumo</p>

            <div className='d-flex flex-row'>
              <div className='d-flex  flex-column wp225'>
                <span className='d-flex flex-column mb-1'>
                  <span className='fw200 fs08 textContrastColor'>Imóvel</span>
                  <span className=''>{curr(obj.imovel.valor)}</span>
                </span>

                <span className='d-flex flex-column mb-1'>
                  <span className='fw200 fs08 textContrastColor'>Entrada</span>
                  <span className=''>{curr(obj.resumo.entrada)}</span>
                </span>

                <span className='d-flex flex-column mb-1'>
                  <span className='fw200 fs08 textContrastColor'>
                    Financiado
                  </span>
                  <span className=''>
                    {curr(obj.imovel.valor - obj.resumo.entrada)}
                  </span>
                </span>

                <span className='d-flex flex-column mb-1'>
                  <span className='fw200 fs08 textContrastColor'>
                    Renda Total
                  </span>
                  <span className=''>{curr(obj.resumo.rendas)}</span>
                </span>

                <span className='d-flex flex-column mb-1'>
                  <span className='fw200 fs08 textContrastColor'>
                    Mais Velho
                  </span>
                  <span className=''>{obj.perfil.mais_velho}</span>
                </span>
              </div>
              <div className='d-flex flex-column fs10 wp225'>
                <ModalLine
                  title='Foi beneficiado'
                  resumo={true}
                  first={
                    isTrue([obj.perfil.beneficiado]) ? (
                      <PKG.IconsUI
                        info={PKG.ui.misc.check.color}
                        clsnm=''
                      />
                    ) : (
                      <PKG.IconsUI
                        info={PKG.ui.misc.cross}
                        clsnm=''
                      />
                    )
                  }
                />
                <ModalLine
                  title='Imóvel registrado'
                  resumo={true}
                  first={
                    obj.perfil.possui_imovel ? (
                      <PKG.IconsUI
                        info={PKG.ui.misc.check.color}
                        clsnm=''
                      />
                    ) : (
                      <PKG.IconsUI
                        info={PKG.ui.misc.cross}
                        clsnm=''
                      />
                    )
                  }
                  second=''
                />
                {isTrue([obj.perfil.possui_imovel]) ? (
                  <>
                    <ModalLine
                      title='Cidade'
                      resumo={true}
                      first={obj.perfil.possui_imovel_cidade}
                      second=''
                    />
                  </>
                ) : (
                  ''
                )}
                <ModalLine
                  title='Servidor Público'
                  resumo={true}
                  first={
                    isTrue([obj.perfil.servidor_publico]) ? (
                      <PKG.IconsUI
                        info={PKG.ui.misc.check.color}
                        clsnm=''
                      />
                    ) : (
                      <PKG.IconsUI
                        info={PKG.ui.misc.cross}
                        clsnm=''
                      />
                    )
                  }
                />
                <ModalLine
                  title='3 anos de FGTS'
                  resumo={true}
                  first={
                    isTrue([obj.perfil.anos_fgts]) ? (
                      <PKG.IconsUI
                        info={PKG.ui.misc.check.color}
                        clsnm=''
                      />
                    ) : (
                      <PKG.IconsUI
                        info={PKG.ui.misc.cross}
                        clsnm=''
                      />
                    )
                  }
                />

                <ModalLine
                  title='Dependentes'
                  resumo={true}
                  first={
                    isTrue([obj.perfil.dependentes]) ? (
                      <PKG.IconsUI
                        info={PKG.ui.misc.check.color}
                        clsnm=''
                      />
                    ) : (
                      <PKG.IconsUI
                        info={PKG.ui.misc.cross}
                        clsnm=''
                      />
                    )
                  }
                />
                <ModalLine
                  title='CLT'
                  resumo={true}
                  first={
                    isTrue([obj.perfil.clt]) ? (
                      <PKG.IconsUI
                        info={PKG.ui.misc.check.color}
                        clsnm=''
                      />
                    ) : (
                      <PKG.IconsUI
                        info={PKG.ui.misc.cross}
                        clsnm=''
                      />
                    )
                  }
                />
              </div>
            </div>
          </div>
        </div>
      </>

      {obj.hasOwnProperty('sim_anotacoes') ? (
        <>
          <div>
            <div className='d-flex flex-row justify-content-between'>
              <span className='sp-anotacoes-title'>Anotações Internas</span>
              <span onClick={editAnotacoes}>
                <PKG.IconsUI
                  info={PKG.ui.misc.edit}
                  clsnm='hov-blue mousePointerHover'
                />
              </span>
            </div>
            <p
              className=''
              id={`p_anotacoes_${obj.SIM_ID}`}
            >
              {obj.SIM_ANOTACOES}
            </p>
            <div
              className='divHide'
              id={`div_anotacoes_${obj.SIM_ID}`}
            >
              {loading ? (
                <Spinner
                  animation='border'
                  variant='success'
                  size='sm'
                />
              ) : (
                <>
                  <Form.Control
                    as='textarea'
                    rows={3}
                    id={`txt_anotacoes_${obj.SIM_ID}`}
                  />
                  <div className='d-flex flex-row justify-content-end mt-2'>
                    <Button
                      className='mx-1 hc-modal-btn'
                      size='sm'
                      variant='outline-secondary'
                      id={`btn_cancel_${obj.SIM_ID}`}
                      onClick={cancel_anot}
                    >
                      Cancelar
                    </Button>
                    <Button
                      className='mx-1 hc-modal-btn'
                      size='sm'
                      variant='outline-success'
                      id={`btn_save_${obj.SIM_ID}`}
                      onClick={save_anot}
                    >
                      Salvar
                    </Button>
                  </div>
                </>
              )}
            </div>
          </div>
        </>
      ) : (
        ''
      )}
      <>
        <hr data-html2canvas-ignore={true} />
        <div className='d-flex flex-row justify-content-between'>
          <span className='sp-documentos-title'>Anexos</span>
        </div>
        <div>
          {objFiles.map((file, index) => {
            return (
              <div
                key={`${index}_${file.SIM_DOC_CODIGO}`}
                className='d-flex flex-row align-items-center justify-content-between hover-bg-light-grey'
              >
                <div className='w100'>
                  <span>{file.SIM_DOC_TITLE}</span>

                  <BlobViewer
                    blobCode={file.SIM_DOC_CODIGO}
                    blobIcon={fileIcon(file.SIM_DOC_FILES_TYPE)}
                    blobTitle={file.SIM_DOC_TITLE}
                    blobName={file.SIM_DOC_FILES_NAME}
                    blobType={file.SIM_DOC_FILES_TYPE}
                    cod_doc_sim={obj.SIM_CODIGO}
                    docSim={true}
                  />
                </div>
                <span
                  className={`ms-1 c-red`}
                  onClick={() =>
                    handleDelete(obj.SIM_CODIGO, file.SIM_DOC_CODIGO)
                  }
                >
                  <PKG.IconsUI
                    info={PKG.ui.action.delete.trash}
                    clsnm='mousePointerHover'
                  />
                </span>
              </div>
            );
          })}
        </div>
        <div data-html2canvas-ignore={true}>
          <DragAndDrop
            onFilesSelected={setFiles}
            filesSt={files}
            docs={a.documentos}
          />
        </div>
        {files.length > 0 ? (
          <div
            className='mt-2 d-flex flex-row justify-content-center '
            data-html2canvas-ignore={true}
          >
            <div>
              {uploading ? (
                <RotatingSquare
                  visible={true}
                  height='80'
                  width='80'
                  color='#6cbe00'
                  ariaLabel='rotating-square-loading'
                  wrapperStyle={{}}
                  wrapperClass=''
                />
              ) : (
                <Button
                  variant='primary'
                  className='ms-3 ffSoft'
                  size='sm'
                  onClick={() => {
                    uploadFilesToServer();
                  }}
                >
                  Enviar Arquivos
                </Button>
              )}
            </div>
          </div>
        ) : (
          ''
        )}

        <hr  data-html2canvas-ignore={true} />
        <>
          <Financiamentos
            vr_renda={obj.resumo.rendas}
            vr_imovel={obj.imovel.valor}
            vr_entrada={obj.resumo.entrada}
            dt_nascimento={obj.perfil.mais_velho}
            fgts_3anos={obj.perfil.anos_fgts}
            beneficiado_mcmv={obj.perfil.beneficiado}
            possui_imovel_reg={obj.perfil.possui_imovel}
            ismodal={true}
            full={full}
          />
        </>

        <div
          className='d-flex flex-column'
          data-html2canvas-ignore={true}
        >
          <span className='mt-4 mb-1 sp-documentos-title'>
            Enviar por e-mail para:
          </span>
          <div className='d-flex flex-row align-items-center justify-content-between '>
            <input
              type='text'
              className='form-control f08'
              name='mailto'
              onChange={({ target: { value } }) => setEmail(value)}
              value={email}
              id='mailto'
            />
            {sendingEmail ? (
              <Spinner
                animation='border'
                variant='success'
                size='sm'
              />
            ) : (
              <Button
                variant='success'
                className='ms-3 ffSoft'
                size='sm'
                onClick={() => {
                  sendPdfToMail();
                }}
              >
                Enviar
              </Button>
            )}
          </div>
          <span
            id='info-email-sent'
            className='ffSoft f08 my-1'
          >
            {infoEmail}
          </span>
        </div>
        <hr />
        <p className='mt-2 f07 fw300 text-end'>
          {new Date().toLocaleDateString('pt-BR')} às{' '}
          {new Date().toLocaleTimeString('pt-BR')}
        </p>
      </>
    </div>
  );
}
